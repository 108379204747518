/**
 * Imports
 */
import * as React from 'react';
import { maxBy, minBy, orderBy, sumBy } from 'lodash';
import { SMIDT } from 'apis';

/**
 * Types
 */
export type FplApiAPIHandlers = {
  onGetStats: () => Promise<void>;
};
export type OverallStats = {
  total_points: number;
};
export type StatsEntityType = {
  name: string;
  event_transfers_cost: number;
  points_on_bench: number;
  event_transfers: number;
  total_points: number;
  highest: number;
  lowest: number;
};
export type FplApiAPIType = {
  handlers: FplApiAPIHandlers;
  isLoading: boolean;
  stats: StatsEntityType[];
  overAllStats: OverallStats[];
};

const _formatData = (data: typeof SMIDT) => {
  const event_transfers_cost = sumBy(data.current, 'event_transfers_cost');
  const points_on_bench = sumBy(data.current, 'points_on_bench');
  const event_transfers = sumBy(data.current, 'event_transfers');
  const total_points = data.current[data.current.length - 1].total_points;
  const highest = maxBy(data.current, 'points')?.points;
  const lowest = minBy(data.current, 'points')?.points;
  return {
    name: data.name,
    event_transfers_cost,
    points_on_bench,
    event_transfers,
    total_points,
    highest,
    lowest,
  };
};
const _formatOverall = (data: typeof SMIDT) => {
  const total_points = sumBy(data.past, 'total_points');
  const seasons = data.past.length;
  const highest = maxBy(data.past, 'total_points');
  const lowest = minBy(data.past, 'total_points');
  return {
    total_points,
    name: data.name,
    seasons,
    highest: highest?.total_points,
    lowest: lowest?.total_points,
    highestSeasonName: highest?.season_name,
    lowestSeasonName: lowest?.season_name,
  };
};

/**
 * useFplApi
 */
// @ts-ignore
const useFplApi = (fplDataArray: typeof SMIDT[]): FplApiAPIType => {
  const [stats, setStats] = React.useState<StatsEntityType[]>([]);
  const [overAllStats, setOverAllStats] = React.useState<OverallStats[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const _formatStats = () => {
    // @ts-ignore
    const data = fplDataArray.map((e) => _formatData(e));
    const orderedData = orderBy(data, 'total_points', ['desc']);
    // @ts-ignore
    setStats(orderedData);
  };

  const _overAllStats = () => {
    const total = fplDataArray.map((fplData) => _formatOverall(fplData));
    const orderedData = orderBy(total, 'total_points', ['desc']);
    setOverAllStats(orderedData);
  };

  React.useEffect(() => {
    _overAllStats();
    _formatStats();
  }, []);

  const handlers: FplApiAPIHandlers = {
    /**
     * onGetStats
     */
    onGetStats: async () => {
      setIsLoading(true);
      setIsLoading(false);
    },
  };

  return { handlers, isLoading, stats, overAllStats };
};

/**
 * Exports
 */
export { useFplApi };
