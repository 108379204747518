export const JOHN = {
  name: 'John',
  current: [
    {
      event: 1,
      points: 14,
      total_points: 14,
      rank: 5643763,
      rank_sort: 5649401,
      overall_rank: 5643763,
      bank: 5,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 2,
      points: 38,
      total_points: 52,
      rank: 5761187,
      rank_sort: 5774726,
      overall_rank: 6037200,
      bank: 10,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 3,
      points: 71,
      total_points: 123,
      rank: 138350,
      rank_sort: 140506,
      overall_rank: 4800669,
      bank: 14,
      value: 1003,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 4,
      points: 80,
      total_points: 203,
      rank: 297823,
      rank_sort: 301782,
      overall_rank: 2472387,
      bank: 29,
      value: 1005,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 5,
      points: 47,
      total_points: 250,
      rank: 5562537,
      rank_sort: 5573712,
      overall_rank: 3183022,
      bank: 27,
      value: 1008,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 6,
      points: 23,
      total_points: 269,
      rank: 7144707,
      rank_sort: 7145400,
      overall_rank: 4500169,
      bank: 45,
      value: 1010,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 7,
      points: 83,
      total_points: 352,
      rank: 81768,
      rank_sort: 83524,
      overall_rank: 3149344,
      bank: 45,
      value: 1012,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 8,
      points: 90,
      total_points: 442,
      rank: 28205,
      rank_sort: 29264,
      overall_rank: 1724600,
      bank: 4,
      value: 1012,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 9,
      points: 60,
      total_points: 502,
      rank: 3024899,
      rank_sort: 3044678,
      overall_rank: 1677080,
      bank: 10,
      value: 1016,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 10,
      points: 51,
      total_points: 553,
      rank: 2279945,
      rank_sort: 2309634,
      overall_rank: 1546321,
      bank: 5,
      value: 1015,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 11,
      points: 78,
      total_points: 631,
      rank: 1007337,
      rank_sort: 1022012,
      overall_rank: 1216581,
      bank: 5,
      value: 1019,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 7,
    },
    {
      event: 12,
      points: 61,
      total_points: 692,
      rank: 1898956,
      rank_sort: 1923879,
      overall_rank: 1133666,
      bank: 20,
      value: 1020,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 13,
      points: 35,
      total_points: 723,
      rank: 6123767,
      rank_sort: 6134110,
      overall_rank: 1517735,
      bank: 17,
      value: 1023,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 14,
    },
    {
      event: 14,
      points: 82,
      total_points: 805,
      rank: 812634,
      rank_sort: 824547,
      overall_rank: 1158309,
      bank: 14,
      value: 1024,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 15,
      points: 51,
      total_points: 856,
      rank: 1801409,
      rank_sort: 1823343,
      overall_rank: 1054280,
      bank: 2,
      value: 1030,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 16,
      points: 37,
      total_points: 893,
      rank: 4129790,
      rank_sort: 4150392,
      overall_rank: 1176042,
      bank: 1,
      value: 1030,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 17,
      points: 43,
      total_points: 932,
      rank: 6342836,
      rank_sort: 6346627,
      overall_rank: 1680946,
      bank: 0,
      value: 1030,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 40,
      total_points: 972,
      rank: 2028854,
      rank_sort: 2056157,
      overall_rank: 1576097,
      bank: 32,
      value: 1025,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 96,
      total_points: 1068,
      rank: 1191310,
      rank_sort: 1197893,
      overall_rank: 1334877,
      bank: 27,
      value: 1027,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 20,
      points: 86,
      total_points: 1150,
      rank: 20521,
      rank_sort: 20883,
      overall_rank: 779436,
      bank: 7,
      value: 1030,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 21,
      points: 43,
      total_points: 1193,
      rank: 4994970,
      rank_sort: 5007973,
      overall_rank: 933815,
      bank: 11,
      value: 1036,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 22,
      points: 60,
      total_points: 1245,
      rank: 3815884,
      rank_sort: 3817989,
      overall_rank: 1200416,
      bank: 4,
      value: 1034,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 12,
    },
    {
      event: 23,
      points: 74,
      total_points: 1319,
      rank: 1306904,
      rank_sort: 1326779,
      overall_rank: 1092887,
      bank: 0,
      value: 1041,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 18,
    },
    {
      event: 24,
      points: 95,
      total_points: 1414,
      rank: 666463,
      rank_sort: 675498,
      overall_rank: 896420,
      bank: 38,
      value: 1045,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 25,
      points: 80,
      total_points: 1490,
      rank: 731869,
      rank_sort: 743986,
      overall_rank: 825704,
      bank: 48,
      value: 1048,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 5,
    },
    {
      event: 26,
      points: 65,
      total_points: 1551,
      rank: 5972739,
      rank_sort: 5980111,
      overall_rank: 1026476,
      bank: 49,
      value: 1047,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 19,
    },
    {
      event: 27,
      points: 72,
      total_points: 1615,
      rank: 2300335,
      rank_sort: 2302530,
      overall_rank: 1070542,
      bank: 36,
      value: 1048,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 11,
    },
    {
      event: 28,
      points: 44,
      total_points: 1651,
      rank: 4064312,
      rank_sort: 4069299,
      overall_rank: 1196439,
      bank: 14,
      value: 1052,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 10,
    },
    {
      event: 29,
      points: 39,
      total_points: 1690,
      rank: 1728708,
      rank_sort: 1739281,
      overall_rank: 1175255,
      bank: 15,
      value: 1057,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 30,
      points: 50,
      total_points: 1736,
      rank: 3333164,
      rank_sort: 3342163,
      overall_rank: 1233537,
      bank: 14,
      value: 1053,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 5,
    },
    {
      event: 31,
      points: 51,
      total_points: 1783,
      rank: 1918874,
      rank_sort: 1931694,
      overall_rank: 1221022,
      bank: 47,
      value: 1052,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 21,
    },
    {
      event: 32,
      points: 63,
      total_points: 1846,
      rank: 1380835,
      rank_sort: 1393509,
      overall_rank: 1141473,
      bank: 53,
      value: 1054,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 33,
      points: 46,
      total_points: 1892,
      rank: 1925694,
      rank_sort: 1936668,
      overall_rank: 1103688,
      bank: 29,
      value: 1056,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 34,
      points: 44,
      total_points: 1936,
      rank: 2896400,
      rank_sort: 2912151,
      overall_rank: 1068771,
      bank: 13,
      value: 1055,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 35,
      points: 95,
      total_points: 2027,
      rank: 2467166,
      rank_sort: 2475285,
      overall_rank: 1114361,
      bank: 14,
      value: 1056,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 18,
    },
    {
      event: 36,
      points: 56,
      total_points: 2083,
      rank: 888310,
      rank_sort: 895825,
      overall_rank: 1019543,
      bank: 22,
      value: 1051,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 37,
      points: 54,
      total_points: 2133,
      rank: 1743103,
      rank_sort: 1753283,
      overall_rank: 1014235,
      bank: 2,
      value: 1052,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 10,
    },
    {
      event: 38,
      points: 42,
      total_points: 2171,
      rank: 5987946,
      rank_sort: 5995395,
      overall_rank: 1119786,
      bank: 10,
      value: 1053,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 1,
    },
  ],
  past: [
    { season_name: '2018/19', total_points: 2024, rank: 1598313 },
    { season_name: '2019/20', total_points: 1827, rank: 3970727 },
  ],
  chips: [
    { name: 'wildcard', time: '2020-09-13T04:50:57.171966Z', event: 2 },
    { name: '3xc', time: '2020-10-17T13:10:30.958417Z', event: 6 },
    { name: 'freehit', time: '2020-12-03T08:44:26.920627Z', event: 11 },
    { name: 'wildcard', time: '2021-01-03T07:52:54.356835Z', event: 18 },
    { name: 'bboost', time: '2021-01-22T20:27:22.752878Z', event: 20 },
  ],
};
