export const DANIEL_22 = {
  name: 'DC',
  current: [
    {
      event: 1,
      points: 57,
      total_points: 57,
      rank: 4573597,
      rank_sort: 4619548,
      overall_rank: 4573588,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 2,
      points: 58,
      total_points: 115,
      rank: 3277965,
      rank_sort: 3315892,
      overall_rank: 3949016,
      bank: 0,
      value: 1000,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 14,
    },
    {
      event: 3,
      points: 51,
      total_points: 162,
      rank: 4807450,
      rank_sort: 4831443,
      overall_rank: 4298897,
      bank: 13,
      value: 1000,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 1,
    },
    {
      event: 4,
      points: 48,
      total_points: 206,
      rank: 6149399,
      rank_sort: 6165131,
      overall_rank: 4832177,
      bank: 18,
      value: 997,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 5,
      points: 67,
      total_points: 273,
      rank: 1383524,
      rank_sort: 1420673,
      overall_rank: 4007063,
      bank: 18,
      value: 997,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 6,
      points: 49,
      total_points: 318,
      rank: 2427850,
      rank_sort: 2434983,
      overall_rank: 3859285,
      bank: 22,
      value: 1000,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 7,
      points: 55,
      total_points: 373,
      rank: 485935,
      rank_sort: 507996,
      overall_rank: 3071381,
      bank: 22,
      value: 1002,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 8,
      points: 50,
      total_points: 423,
      rank: 4019672,
      rank_sort: 4075661,
      overall_rank: 3061731,
      bank: 22,
      value: 1006,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 9,
      points: 52,
      total_points: 475,
      rank: 5651303,
      rank_sort: 5675306,
      overall_rank: 3574026,
      bank: 22,
      value: 1009,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 10,
      points: 37,
      total_points: 508,
      rank: 5854890,
      rank_sort: 5865182,
      overall_rank: 3804607,
      bank: 19,
      value: 1007,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 11,
      points: 42,
      total_points: 546,
      rank: 4421229,
      rank_sort: 4442922,
      overall_rank: 3902376,
      bank: 0,
      value: 1010,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 1,
    },
    {
      event: 12,
      points: 73,
      total_points: 619,
      rank: 1216591,
      rank_sort: 1238522,
      overall_rank: 3469290,
      bank: 0,
      value: 1010,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 13,
      points: 74,
      total_points: 693,
      rank: 95458,
      rank_sort: 98974,
      overall_rank: 2689054,
      bank: 0,
      value: 1011,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 14,
      points: 78,
      total_points: 771,
      rank: 607525,
      rank_sort: 635239,
      overall_rank: 2323038,
      bank: 0,
      value: 1014,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 15,
      points: 62,
      total_points: 833,
      rank: 423403,
      rank_sort: 438277,
      overall_rank: 1914614,
      bank: 0,
      value: 1018,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 16,
      points: 64,
      total_points: 897,
      rank: 2666711,
      rank_sort: 2700302,
      overall_rank: 1835393,
      bank: 0,
      value: 1022,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 17,
      points: 53,
      total_points: 950,
      rank: 2808942,
      rank_sort: 2834207,
      overall_rank: 1788838,
      bank: 35,
      value: 1021,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 48,
      total_points: 998,
      rank: 2620519,
      rank_sort: 2657852,
      overall_rank: 1713791,
      bank: 35,
      value: 1024,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 30,
      total_points: 1028,
      rank: 5637135,
      rank_sort: 5698604,
      overall_rank: 1874968,
      bank: 35,
      value: 1027,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 20,
      points: 24,
      total_points: 1052,
      rank: 7544671,
      rank_sort: 7598743,
      overall_rank: 2101519,
      bank: 35,
      value: 1028,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 21,
      points: 31,
      total_points: 1083,
      rank: 7223522,
      rank_sort: 7277411,
      overall_rank: 2454572,
      bank: 35,
      value: 1028,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 22,
      points: 32,
      total_points: 1115,
      rank: 7778212,
      rank_sort: 7811730,
      overall_rank: 2800575,
      bank: 35,
      value: 1028,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 23,
      points: 60,
      total_points: 1167,
      rank: 109299,
      rank_sort: 109525,
      overall_rank: 2504891,
      bank: 14,
      value: 1023,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 1,
    },
    {
      event: 24,
      points: 47,
      total_points: 1210,
      rank: 4697372,
      rank_sort: 4705045,
      overall_rank: 2707265,
      bank: 21,
      value: 1023,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 25,
      points: 42,
      total_points: 1252,
      rank: 5552074,
      rank_sort: 5616670,
      overall_rank: 2794405,
      bank: 21,
      value: 1022,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 26,
      points: 99,
      total_points: 1351,
      rank: 3052197,
      rank_sort: 3076479,
      overall_rank: 2687991,
      bank: 21,
      value: 1020,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 27,
      points: 37,
      total_points: 1384,
      rank: 5384075,
      rank_sort: 5389918,
      overall_rank: 2791934,
      bank: 24,
      value: 1014,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 28,
      points: 78,
      total_points: 1458,
      rank: 2897201,
      rank_sort: 2907224,
      overall_rank: 2748555,
      bank: 15,
      value: 1015,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 29,
      points: 60,
      total_points: 1518,
      rank: 6662819,
      rank_sort: 6702826,
      overall_rank: 2938832,
      bank: 15,
      value: 1015,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 11,
    },
    {
      event: 30,
      points: 15,
      total_points: 1533,
      rank: 5899678,
      rank_sort: 5968015,
      overall_rank: 3122302,
      bank: 15,
      value: 1016,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 31,
      points: 77,
      total_points: 1610,
      rank: 76926,
      rank_sort: 80501,
      overall_rank: 2756186,
      bank: 15,
      value: 1016,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 32,
      points: 65,
      total_points: 1675,
      rank: 1590428,
      rank_sort: 1603869,
      overall_rank: 2611861,
      bank: 6,
      value: 1015,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 33,
      points: 70,
      total_points: 1745,
      rank: 3128305,
      rank_sort: 3176256,
      overall_rank: 2568429,
      bank: 6,
      value: 1015,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 34,
      points: 46,
      total_points: 1791,
      rank: 5536014,
      rank_sort: 5608443,
      overall_rank: 2624075,
      bank: 6,
      value: 1015,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 35,
      points: 45,
      total_points: 1836,
      rank: 3956174,
      rank_sort: 4036983,
      overall_rank: 2623637,
      bank: 6,
      value: 1015,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 36,
      points: 73,
      total_points: 1909,
      rank: 4983836,
      rank_sort: 5031212,
      overall_rank: 2720586,
      bank: 6,
      value: 1018,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 7,
    },
    {
      event: 37,
      points: 74,
      total_points: 1983,
      rank: 895371,
      rank_sort: 907334,
      overall_rank: 2427186,
      bank: 6,
      value: 1017,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 38,
      points: 57,
      total_points: 2040,
      rank: 3119740,
      rank_sort: 3168996,
      overall_rank: 2428144,
      bank: 6,
      value: 1016,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
  ],
  past: [
    {
      season_name: '2016/17',
      total_points: 1446,
      rank: 3681498,
    },
    {
      season_name: '2017/18',
      total_points: 1833,
      rank: 2553459,
    },
    {
      season_name: '2018/19',
      total_points: 1780,
      rank: 4218932,
    },
    {
      season_name: '2019/20',
      total_points: 1786,
      rank: 4439944,
    },
    {
      season_name: '2020/21',
      total_points: 1720,
      rank: 5143518,
    },
  ],
  chips: [],
};
