/**
 * Imports
 */
import { Text } from 'components';
import { GoogleDriveDocument, MeetingsList } from 'features/meetings';
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledFrame = styled.iframe`
  margin-top: 16px;
  border: none;
  width: 100%;
  min-height: calc(100vh - 200px);
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  @media (max-width: 600px) {
    min-height: 800px;
  }
`;

/**
 * Types
 */
export interface MeetingPageProps {}

/**
 * MeetingPage component
 */
const MeetingPage: React.FC<MeetingPageProps> = (props) => {
  const { meetingId } = useParams<{ meetingId: string }>();
  const LINK = `https://drive.google.com/file/d/${meetingId}/preview`;
  return (
    <BaseLayout pageName="Møde">
      <StyledFrame src={LINK} />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { MeetingPage };
