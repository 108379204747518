export const MADSEN_22 = {
  name: 'Madsen',
  current: [
    {
      event: 1,
      points: 30,
      total_points: 30,
      rank: 6308624,
      rank_sort: 6323166,
      overall_rank: 6308612,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 2,
      points: 48,
      total_points: 78,
      rank: 5119303,
      rank_sort: 5147453,
      overall_rank: 6147230,
      bank: 0,
      value: 999,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 3,
      points: 48,
      total_points: 126,
      rank: 5294529,
      rank_sort: 5320969,
      overall_rank: 6075474,
      bank: 0,
      value: 994,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 4,
      points: 48,
      total_points: 174,
      rank: 6007523,
      rank_sort: 6035350,
      overall_rank: 6140857,
      bank: 0,
      value: 993,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 5,
      points: 40,
      total_points: 214,
      rank: 7013278,
      rank_sort: 7035154,
      overall_rank: 6397991,
      bank: 0,
      value: 993,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 6,
      points: 46,
      total_points: 260,
      rank: 3016353,
      rank_sort: 3058721,
      overall_rank: 6165744,
      bank: 0,
      value: 990,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 7,
      points: 25,
      total_points: 285,
      rank: 7248281,
      rank_sort: 7271188,
      overall_rank: 6377620,
      bank: 0,
      value: 988,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 8,
      points: 49,
      total_points: 334,
      rank: 4274820,
      rank_sort: 4312239,
      overall_rank: 6202930,
      bank: 0,
      value: 989,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 9,
      points: 32,
      total_points: 366,
      rank: 7609915,
      rank_sort: 7623110,
      overall_rank: 6576124,
      bank: 0,
      value: 989,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 10,
      points: 29,
      total_points: 395,
      rank: 7453893,
      rank_sort: 7474818,
      overall_rank: 6744090,
      bank: 0,
      value: 988,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 11,
      points: 24,
      total_points: 419,
      rank: 8068059,
      rank_sort: 8084532,
      overall_rank: 6924419,
      bank: 0,
      value: 985,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 12,
      points: 26,
      total_points: 445,
      rank: 8442580,
      rank_sort: 8448534,
      overall_rank: 7194447,
      bank: 0,
      value: 982,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 13,
      points: 13,
      total_points: 458,
      rank: 8604691,
      rank_sort: 8607901,
      overall_rank: 7427412,
      bank: 0,
      value: 981,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 14,
      points: 9,
      total_points: 467,
      rank: 8681871,
      rank_sort: 8682079,
      overall_rank: 7724462,
      bank: 0,
      value: 981,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 15,
      points: 16,
      total_points: 483,
      rank: 8689050,
      rank_sort: 8690533,
      overall_rank: 7848596,
      bank: 0,
      value: 977,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 16,
      points: 28,
      total_points: 511,
      rank: 8374767,
      rank_sort: 8385155,
      overall_rank: 7934927,
      bank: 0,
      value: 976,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 17,
      points: 32,
      total_points: 543,
      rank: 7020721,
      rank_sort: 7045877,
      overall_rank: 7937452,
      bank: 0,
      value: 976,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 23,
      total_points: 566,
      rank: 7133598,
      rank_sort: 7164125,
      overall_rank: 7965271,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 35,
      total_points: 601,
      rank: 4461891,
      rank_sort: 4501091,
      overall_rank: 7947560,
      bank: 0,
      value: 975,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 20,
      points: 13,
      total_points: 614,
      rank: 8692054,
      rank_sort: 8699959,
      overall_rank: 8048355,
      bank: 0,
      value: 975,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 21,
      points: 22,
      total_points: 636,
      rank: 8376302,
      rank_sort: 8391708,
      overall_rank: 8095844,
      bank: 0,
      value: 975,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 22,
      points: 35,
      total_points: 671,
      rank: 7376351,
      rank_sort: 7401696,
      overall_rank: 8125275,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 23,
      points: 34,
      total_points: 705,
      rank: 4529535,
      rank_sort: 4595222,
      overall_rank: 8112988,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 19,
    },
    {
      event: 24,
      points: 35,
      total_points: 740,
      rank: 6589028,
      rank_sort: 6622851,
      overall_rank: 8120166,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 25,
      points: 19,
      total_points: 759,
      rank: 8813384,
      rank_sort: 8819906,
      overall_rank: 8192506,
      bank: 0,
      value: 973,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 26,
      points: 53,
      total_points: 812,
      rank: 7162544,
      rank_sort: 7181135,
      overall_rank: 8217511,
      bank: 0,
      value: 973,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 13,
    },
    {
      event: 27,
      points: 32,
      total_points: 844,
      rank: 6447261,
      rank_sort: 6494416,
      overall_rank: 8220761,
      bank: 0,
      value: 973,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 28,
      points: 44,
      total_points: 888,
      rank: 7664426,
      rank_sort: 7685940,
      overall_rank: 8252643,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 29,
      points: 38,
      total_points: 926,
      rank: 8561342,
      rank_sort: 8569999,
      overall_rank: 8301070,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 30,
      points: 8,
      total_points: 934,
      rank: 7454578,
      rank_sort: 7496871,
      overall_rank: 8318498,
      bank: 0,
      value: 975,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 31,
      points: 37,
      total_points: 971,
      rank: 6130283,
      rank_sort: 6182810,
      overall_rank: 8314618,
      bank: 0,
      value: 975,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 32,
      points: 15,
      total_points: 986,
      rank: 9003973,
      rank_sort: 9006392,
      overall_rank: 8369774,
      bank: 0,
      value: 975,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 33,
      points: 31,
      total_points: 1017,
      rank: 8531058,
      rank_sort: 8542370,
      overall_rank: 8412938,
      bank: 0,
      value: 977,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 34,
      points: 28,
      total_points: 1045,
      rank: 8672492,
      rank_sort: 8687761,
      overall_rank: 8448058,
      bank: 0,
      value: 976,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 35,
      points: 28,
      total_points: 1073,
      rank: 8167019,
      rank_sort: 8198374,
      overall_rank: 8464603,
      bank: 0,
      value: 976,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 36,
      points: 43,
      total_points: 1116,
      rank: 8730834,
      rank_sort: 8740846,
      overall_rank: 8502876,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 37,
      points: 19,
      total_points: 1135,
      rank: 8324340,
      rank_sort: 8348366,
      overall_rank: 8523966,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 8,
    },
    {
      event: 38,
      points: 23,
      total_points: 1158,
      rank: 8570733,
      rank_sort: 8583506,
      overall_rank: 8544700,
      bank: 0,
      value: 974,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
  ],
  past: [
    {
      season_name: '2016/17',
      total_points: 1477,
      rank: 3587744,
    },
    {
      season_name: '2017/18',
      total_points: 1686,
      rank: 3672710,
    },
    {
      season_name: '2018/19',
      total_points: 1850,
      rank: 3621642,
    },
    {
      season_name: '2019/20',
      total_points: 1789,
      rank: 4403243,
    },
    {
      season_name: '2020/21',
      total_points: 1964,
      rank: 2969934,
    },
  ],
  chips: [],
};
