/**
 * Imports
 */
import { Tabs } from 'antd';
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { styled } from 'theme';
const { TabPane } = Tabs;
/**∏
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

const StyledFrame1 = styled.iframe`
  border: none;
  width: 100%;
  min-height: calc(100vh - 200px);
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
`;
const StyledFrame2 = styled.iframe`
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
`;

/**
 * Types
 */
export interface BreweryListPageProps {}

/**
 * BreweryListPage component
 */
const BreweryListPage: React.FC<BreweryListPageProps> = (props) => {
  return (
    <BaseLayout pageName="Bryg">
      <Tabs defaultActiveKey="1">
        <TabPane tab="IBDb" key="1">
          <StyledFrame1 src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRjM1Uq9WK6WR7aJM9Z3Qnkzf6VNOc4dLPAULtnlnjjcPMxpaXXKyoymCIxevwNlO6DOjY6_sxFPEgY/pubhtml?widget=true&amp;headers=false" />
        </TabPane>
        <TabPane tab="Top 6" key="2">
          <StyledFrame2
            width="240"
            height="240"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRjM1Uq9WK6WR7aJM9Z3Qnkzf6VNOc4dLPAULtnlnjjcPMxpaXXKyoymCIxevwNlO6DOjY6_sxFPEgY/pubhtml?widget=true&amp;headers=false"
          />
        </TabPane>
        <TabPane tab="Bryg svar skema" key="3">
          <StyledFrame2
            src="https://docs.google.com/forms/d/e/1FAIpQLSfcIVL5Poy_DFM_5EoDdmQE07fpkn_JiOUjrBoHGaL-FkxXSA/viewform?embedded=true"
            width="100%"
            height="2000"
            frameBorder="0"
          />
        </TabPane>
      </Tabs>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { BreweryListPage };
