/**
 * Imports
 */
import { Box, Button, Input } from 'components';
import { SplashScreen } from 'features/navigation/components/SplashScreen';
import { userSelector } from 'features/user/redux/selectors';
import { getFirebaseInstance } from 'firebaseAPI';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
const db = getFirebaseInstance();

/**
 * Types
 */
export type MemberData = {
  name: string;
  passport: string;
  address: string;
  mail: string;
  phone: string;
  birthdate: string;
  userId: string;
};
export interface AccountDetailsProps {}

/**
 * AccountDetails component
 */
const AccountDetails: React.FC<AccountDetailsProps> = (props) => {
  const [details, setDetails] = React.useState<MemberData>();
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const user = useSelector(userSelector);
  const { formState, control, handleSubmit, reset } = useForm<MemberData>({});

  const addDetails = async (data: MemberData) => {
    setIsSaving(true);

    await db
      .firestore()
      .collection('users')
      .doc(user.uid)
      .set({ ...details, ...data, userId: user.uid });
    setIsSaving(false);
  };
  const getData = async () => {
    setIsFetching(true);
    const docRef = await db.firestore().collection('users').doc(user.uid).get();
    const data = docRef.data() as MemberData;
    setDetails(data);
    setIsFetching(false);
  };

  const onSubmit = handleSubmit(
    (data) => {
      // Send to firebase
      for (const key in data) {
        // @ts-ignore
        if (data[key] === undefined) {
          // @ts-ignore
          delete data[key];
        }
      }
      addDetails(data);
    },
    ({ name, address, passport }) => {
      console.log('error', { name, address, passport });
    },
  );

  React.useEffect(() => {
    getData();
  }, []);

  if (isFetching) {
    return <SplashScreen />;
  }

  return (
    <Box>
      <Box width="100%" maxWidth={'400px'}>
        <form onSubmit={onSubmit}>
          <Controller
            name="name"
            control={control}
            defaultValue={details?.name}
            render={({ field }) => <Input placeholder={'Navn'} {...field} />}
          />
          <Box mt="16px" />
          <Controller
            name="address"
            control={control}
            defaultValue={details?.address}
            render={({ field }) => (
              <Input placeholder={'Addresse'} {...field} />
            )}
          />
          <Box mt="16px" />
          <Controller
            name="passport"
            defaultValue={details?.passport}
            control={control}
            render={({ field }) => (
              <Input placeholder={'Pas nummer og udløbsdato'} {...field} />
            )}
          />
          <Box mt="16px" />
          <Controller
            name="mail"
            control={control}
            defaultValue={details?.mail}
            render={({ field }) => <Input placeholder={'E-mail'} {...field} />}
          />
          <Box mt="16px" />
          <Controller
            name="phone"
            control={control}
            defaultValue={details?.phone}
            render={({ field }) => <Input placeholder={'Telefon'} {...field} />}
          />
          <Box mt="16px" />
          <Controller
            name="birthdate"
            control={control}
            defaultValue={details?.birthdate}
            render={({ field }) => (
              <Input placeholder={'Fødselsdato'} {...field} />
            )}
          />
          <Box mt="16px" />
          <Button
            type="default"
            loading={isSaving}
            disabled={formState.isSubmitting}
            style={{ borderRadius: '28px' }}
            onClick={onSubmit}
          >
            Gem
          </Button>
        </form>
      </Box>
    </Box>
  );
};

/**
 * Exports
 */
export { AccountDetails };
