/**
 * Imports
 */
import { Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface EntityStatsProps {}

/**
 * EntityStats component
 */
const EntityStats: React.FC<EntityStatsProps> = (props) => {
  return (
    <Root>
      <Text variant="h5">EntityStats</Text>
    </Root>
  );
};

/**
 * Exports
 */
export { EntityStats };
