import { User } from 'features/user/types';
import firebaseNative from 'firebase/app';
import { getFirebaseInstance } from './firebaseAdapter';

/**
 * Notes
 * https://firebase.google.com/docs/reference/js/firebase.auth.GoogleAuthProvider
 * https://gist.github.com/jamestalmage/2d8d1d5c42157caf349e
 * https://developers.google.com/drive/api/v3/reference/files/list#request-body
 * https://github.com/firebase/snippets-web/blob/c5bfca32e881d7a40002285384a784749f973c35/snippets/auth-next/google-signin/auth_google_provider_scopes.js
 */

/**
 * Signup with Google
 */
const firebaseSignupWithGoogle = async (): Promise<any> => {
  try {
    const firebase = getFirebaseInstance();
    console.log('firebaseSignupWithGoogle');
    const result = await firebase
      .auth()
      .signInWithPopup(new firebaseNative.auth.GoogleAuthProvider());
    console.log('result', result);
    if (!result.user) {
      throw new Error('Could not sign user in.');
    }

    return result;
  } catch (exception) {
    throw exception.code;
  }
};

/**
 * Google login
 */
const firebaseGoogleLogin = async (): Promise<any> => {
  const firebase = getFirebaseInstance();
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/drive');
  const resp = await firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      // This gives you a Google Access Token.
      console.log('result', result);
      // @ts-ignore
      const token = result.credential.accessToken;
      // getFiles(token);
      // The signed-in user info.
      // @ts-ignore
      const user = result.user.refreshToken;
      // getFiles(user);
    });

  console.log('resp', resp);
};
const firebaseGoogleLogin2 = async (): Promise<any> => {
  const firebase = getFirebaseInstance();

  const resp = await firebase
    .auth()
    .signInWithPopup(new firebaseNative.auth.GoogleAuthProvider());
  console.log('resp', resp);
  if (!resp.user) {
    throw new Error('Could not sign user in.');
  }

  return resp.user;
};

const firebaseLogin = async (
  email: string,
  password: string,
): Promise<User> => {
  const firebase = getFirebaseInstance();
  const signinResult = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  if (!signinResult.user) {
    throw new Error('Could not sign in user.');
  }

  // @ts-ignore
  return signinResult.user;
};

const getIdToken = async (): Promise<string> => {
  const firebase = getFirebaseInstance();
  const token = await firebase.auth().currentUser!.getIdToken();
  return token;
};

const firebaseLogout = async (): Promise<void> => {
  const firebase = getFirebaseInstance();
  await firebase.auth().signOut();
};

const getUser = async (userId: string) => {};

export {
  firebaseLogout,
  firebaseLogin,
  getIdToken,
  getUser,
  firebaseSignupWithGoogle,
  firebaseGoogleLogin,
};
