/**
 * Imports
 */
import { Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface BreweryListProps {}

/**
 * BreweryList component
 */
const BreweryList: React.FC<BreweryListProps> = (props) => {
  return (
    <Root>
      <Text variant="h5">BreweryList</Text>
    </Root>
  );
};

/**
 * Exports
 */
export { BreweryList };
