/**
 * Imports
 */
import { Card, List, Tabs } from 'antd';
import { Box, Text } from 'components';
import {
  AddMeeting,
  FBMeetingsList,
  GoogleDriveDocumentType,
  MeetingData,
} from 'features/meetings';
import { BaseLayout } from 'features/navigation/components';
import { useGoogleDriveApi } from 'features/shared';
import { MemberData } from 'features/user/components';
import { getFirebaseInstance } from 'firebaseAPI';
import { gapi } from 'gapi-script';
import { countBy, orderBy, sortBy } from 'lodash';
import * as React from 'react';
const db = getFirebaseInstance();
const { TabPane } = Tabs;
/**
 * Types
 */
export interface MeetingsListPageProps {}

/**
 * MeetingsListPage component
 */
const MeetingsListPage: React.FC<MeetingsListPageProps> = (props) => {
  useGoogleDriveApi();
  const [hostCount, setHostCount] = React.useState<Record<string, number>>();
  const [members, setMembers] = React.useState<MemberData[]>();
  const [documents, setDocuments] = React.useState<GoogleDriveDocumentType[]>(
    [],
  );
  const [images, setImages] = React.useState<GoogleDriveDocumentType[]>([]);
  const [
    isFetchingGoogleDriveFiles,
    setIsFetchingGoogleDriveFiles,
  ] = React.useState(false);

  const getMeetings = async () => {
    const docRef = await db.firestore().collection('meetings').get();

    const data = docRef.docs.map((doc) => doc.data() as MeetingData);
    const count = countBy(data, 'host');
    setHostCount(count);
  };

  const getData = async () => {
    const docRef = await db.firestore().collection('users').get();

    const data = docRef.docs.map((doc) => doc.data() as MemberData);
    const filteredData = orderBy(data, 'name', ['asc']);
    setMembers(filteredData);
  };
  /**
   * Print files.
   */
  const listImages = async (searchTerm = null) => {
    const ID_OF_THE_FOLDER = '1ESA3B2nZ1hxx1dwh8JrzBl7uHAIISxAH';
    const driveApi = gapi.client.drive;
    setIsFetchingGoogleDriveFiles(true);
    const response = await driveApi.files.list({
      // Meta file props: https://developers.google.com/drive/api/v3/reference/files
      fields:
        'files(id, name, mimeType, modifiedTime, webViewLink, webContentLink)',
      // q: searchTerm,
      // https://developers.google.com/drive/api/v3/reference/query-ref
      q: `'${ID_OF_THE_FOLDER}' in parents and trashed=false`,
    });
    setIsFetchingGoogleDriveFiles(false);
    const res = JSON.parse(response.body);
    const onlyFiles = res.files.filter(
      (file) => file.mimeType === 'image/jpeg',
    );
    const orderedFiles = sortBy(onlyFiles, (file) =>
      parseInt(file.name, 10),
    ).reverse();
    setImages(orderedFiles);
  };

  const listFiles = async (searchTerm = null) => {
    const ID_OF_THE_FOLDER =
      '0B9iHxSUXvFPufkROUFNnUmQwZk9zZExQbWsyMVlNUWxmZkFvWVlQNUJycTZ0Q1FFNUJCVnM';
    const driveApi = gapi.client.drive;
    setIsFetchingGoogleDriveFiles(true);
    const response = await driveApi.files.list({
      // Meta file props: https://developers.google.com/drive/api/v3/reference/files
      fields:
        'files(id, name, mimeType, modifiedTime, webViewLink, webContentLink)',
      // q: searchTerm,
      // https://developers.google.com/drive/api/v3/reference/query-ref
      q: `'${ID_OF_THE_FOLDER}' in parents and trashed=false`,
    });
    setIsFetchingGoogleDriveFiles(false);
    const res = JSON.parse(response.body);
    const onlyFiles = res.files.filter(
      (file) => file.mimeType !== 'application/vnd.google-apps.folder',
    );
    const orderedFiles = sortBy(onlyFiles, (file) =>
      parseInt(file.name, 10),
    ).reverse();
    setDocuments(orderedFiles);
  };

  React.useEffect(() => {
    if (gapi.client) {
      listFiles();
      listImages();
      getData();
      getMeetings();
    }
  }, [gapi.client]);

  return (
    <BaseLayout
      pageName="Møder"
      header={
        <AddMeeting documents={documents} images={images} members={members} />
      }
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Møder" key="1">
          <FBMeetingsList />
        </TabPane>
        <TabPane tab="Mødeholdere" key="2">
          <Box width={'100%'} maxWidth={'320px'}>
            <Card>
              <List>
                {hostCount &&
                  Object.entries(hostCount).map((d) => {
                    return (
                      <List.Item key={d[0]}>
                        <Text variant="ui3">
                          {d[0]}: {d[1]}
                        </Text>
                      </List.Item>
                    );
                  })}
              </List>
            </Card>
          </Box>
        </TabPane>
      </Tabs>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { MeetingsListPage };
