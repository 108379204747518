/**
 * Imports
 */
import { Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';
import { MobileMenu } from '..';
import { Sidebar } from '../Sidebar';

/**
 * Styling
 */
const Body = styled.div`
  display: flex;
  flex: 1;
`;
const Main = styled.div`
  height: 100%;
  padding: 48px 48px 0px 48px;

  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 600px) {
    padding: 16px 24px;
    max-width: 100vw;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  width: 100%;
  align-self: center;
  flex: 1;
  padding: 24px 0;

  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/**
 * Types
 */
export interface BaseLayoutProps {
  pageName?: string;
  header?: React.ReactNode;
}

/**
 * BaseLayout component
 */
const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  return (
    <Body>
      <Sidebar />
      <Column>
        <MobileMenu />
        <Main>
          <TitleContainer>
            {props.pageName && <Text variant="h3"> {props.pageName} </Text>}
            {props.header && props.header}
          </TitleContainer>
          <Content>{props.children}</Content>
        </Main>
      </Column>
    </Body>
  );
};

/**
 * Exports
 */
export { BaseLayout };
