/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.user;

export const maybeUserSelector = createSelector(
  [rootSelector],
  (root) => root.user,
);

export const userSelector = createSelector(
  [rootSelector],
  (root) => root.user!,
);

export const isAdminSelector = createSelector(
  [rootSelector],
  (root) => root.user?.uid === 'siH3Dnt1HAMbvu5q4WIJ6WwIOA43',
);
