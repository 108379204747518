/**
 * Imports
 */
import { Row, Table } from 'antd';
import { Box, Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';
import { useFplApi } from 'features/fpl/api';

/**
 * CONST
 */

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px 0;
`;

/**
 * Types
 */
export interface OverallTableProps {
  data: any;
  title: string;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => <Text variant="ui3">{name}</Text>,
  },
  {
    title: 'Total points',
    dataIndex: 'total_points',
    key: 'total_points',
    sorter: {
      compare: (a: any, b: any) => a.total_points - b.total_points,
    },
    render: (total_points: string) => <Text variant="ui3">{total_points}</Text>,
  },
  {
    title: 'Seasons',
    dataIndex: 'seasons',
    key: 'seasons',
    sorter: {
      compare: (a: any, b: any) => a.seasons - b.seasons,
    },
    render: (seasons: string) => <Text variant="ui3">{seasons}</Text>,
  },

  {
    title: 'Highest',
    dataIndex: 'highest',
    key: 'highest',
    sorter: {
      compare: (a: any, b: any) => a.highest - b.highest,
    },
    render: (highest: string, record: any) => (
      <Row>
        <Text variant="ui3">{highest}</Text>
        <Box m="2px" />
        <Text variant="ui3" color={'Text.UI'}>
          ({record.highestSeasonName})
        </Text>
      </Row>
    ),
  },
  {
    title: 'Lowest',
    dataIndex: 'lowest',
    key: 'lowest',
    sorter: {
      compare: (a: any, b: any) => a.lowest - b.lowest,
    },
    render: (lowest: string, record: any) => (
      <Row>
        <Text variant="ui3">{lowest}</Text>
        <Box m="2px" />
        <Text variant="ui3" color={'Text.UI'}>
          ({record.lowestSeasonName})
        </Text>
      </Row>
    ),
  },
];

/**
 * OverallTable component
 */
const OverallTable: React.FC<OverallTableProps> = (props) => {
  const { overAllStats } = useFplApi(props.data);

  if (overAllStats.length === 0) {
    return <Text variant="h5">...loading</Text>;
  }
  return (
    <Root>
      <Text variant="h5">{props.title}</Text>
      <Table scroll={{ x: 400 }} columns={columns} dataSource={overAllStats} />
    </Root>
  );
};

/**
 * Exports
 */
export { OverallTable };
