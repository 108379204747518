/**
 * Imports
 */
import {
  BookOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  FolderOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Text } from 'components';
import {
  collapseSidebar,
  openSidebar,
} from 'features/navigation/redux/actions';
import { sidebarCollapsedSelector } from 'features/navigation/redux/selectors';
import { firebaseLogout } from 'firebaseAPI';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'router';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledMenu = styled(Menu)`
  && {
    background-color: #dfe6ee;

    .ant-menu-item-selected {
      background-color: #9fb7cc;
    }
  }
  height: 100%;
  max-width: 180px;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    display: none;
  }
`;
const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;
const ButtonMenuItem = styled(Menu.Item)`
  margin-top: auto !important;
  display: flex;
  align-items: center;
`;
const Top = styled(Menu.Item)`
  cursor: pointer;
  text-align: left;
  height: 48px;
  margin-bottom: 48px !important;
`;
const CollapseButton = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;
const LogoutButton = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;

/**
 * Types
 */
export interface SidebarProps {}

/**
 * Sidebar component
 */
const Sidebar: React.FC<SidebarProps> = (_props) => {
  const [currentPath, setCurrentPath] = React.useState('string');
  const collapsed = useSelector(sidebarCollapsedSelector);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const toggleCollaped = () => {
    dispatch(collapsed ? openSidebar({}) : collapseSidebar({}));
  };

  const _navigateTo = (path: string) => {
    navigate.push(path);
  };

  /**
   *  Handle logout
   */
  const _handleLogout = async () => {
    await firebaseLogout();
    navigate.replace({ pathname: '/login' });
  };

  React.useEffect(() => {
    const pathName = location.pathname.split('/')[1];
    setCurrentPath(`/${[pathName]}`);
  }, [location.pathname]);

  return (
    <StyledMenu
      mode="inline"
      inlineCollapsed={collapsed}
      selectedKeys={[currentPath]}
    >
      <Top onClick={() => _navigateTo(ROUTES.home.path)}>FRI13</Top>
      <MenuItem
        key={ROUTES.home.path}
        icon={<HomeOutlined />}
        onClick={() => _navigateTo(`${ROUTES.home.path}`)}
      >
        <Text variant="ui2">Forside</Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.rules.path}
        icon={<BookOutlined />}
        onClick={() => _navigateTo(`${ROUTES.rules.path}`)}
      >
        <Text variant="ui2">Reglementer</Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.meetings.path}
        icon={<FileTextOutlined />}
        onClick={() => _navigateTo(`${ROUTES.meetings.path}`)}
      >
        <Text variant="ui2">Møder</Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.materials.path}
        icon={<FolderOutlined />}
        onClick={() => _navigateTo(`${ROUTES.materials.path}`)}
      >
        <Text variant="ui2">Materiale</Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.brewery.path}
        icon={<ExperimentOutlined />}
        onClick={() => _navigateTo(`${ROUTES.brewery.path}`)}
      >
        <Text variant="ui2">Bryg</Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.fpl.path}
        icon={<TrophyOutlined />}
        onClick={() => _navigateTo(`${ROUTES.fpl.path}`)}
      >
        <Text variant="ui2">FPL</Text>
      </MenuItem>
      <ButtonMenuItem
        key={ROUTES.account.path}
        icon={<UserOutlined />}
        onClick={() => _navigateTo(ROUTES.account.path)}
      >
        <Text variant="ui2">Konto</Text>
      </ButtonMenuItem>
      <LogoutButton onClick={_handleLogout} icon={<LogoutOutlined />}>
        <Text variant="ui2">
          <Trans i18nKey="Global.actions.btn_logout" />
        </Text>
      </LogoutButton>
      <CollapseButton
        onClick={toggleCollaped}
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      >
        <Text variant="ui2">
          <Trans i18nKey="Sidebar.actions.btn_collapse" />
        </Text>
      </CollapseButton>
    </StyledMenu>
  );
};

/**
 * Exports
 */
export { Sidebar };
