export const DANIEL = {
  name: 'DC',
  current: [
    {
      event: 1,
      points: 39,
      total_points: 39,
      rank: 3934196,
      rank_sort: 4012239,
      overall_rank: 3934196,
      bank: 5,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 2,
      points: 73,
      total_points: 112,
      rank: 1360847,
      rank_sort: 1380695,
      overall_rank: 2466911,
      bank: 5,
      value: 999,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 3,
      points: 65,
      total_points: 169,
      rank: 348915,
      rank_sort: 350216,
      overall_rank: 1434512,
      bank: 0,
      value: 996,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 4,
      points: 17,
      total_points: 186,
      rank: 6829797,
      rank_sort: 6841335,
      overall_rank: 3563477,
      bank: 0,
      value: 996,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 5,
      points: 65,
      total_points: 251,
      rank: 2777054,
      rank_sort: 2804438,
      overall_rank: 3139095,
      bank: 0,
      value: 995,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 6,
      points: 43,
      total_points: 294,
      rank: 4734484,
      rank_sort: 4764768,
      overall_rank: 3387979,
      bank: 8,
      value: 996,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 7,
      points: 47,
      total_points: 341,
      rank: 4993156,
      rank_sort: 5043742,
      overall_rank: 3655974,
      bank: 8,
      value: 993,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 8,
      points: 43,
      total_points: 376,
      rank: 5993425,
      rank_sort: 5994067,
      overall_rank: 4380191,
      bank: 0,
      value: 994,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 9,
      points: 57,
      total_points: 421,
      rank: 3700108,
      rank_sort: 3702735,
      overall_rank: 4624252,
      bank: 38,
      value: 996,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 0,
    },
    {
      event: 10,
      points: 37,
      total_points: 458,
      rank: 5344276,
      rank_sort: 5395921,
      overall_rank: 4743440,
      bank: 38,
      value: 999,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 11,
      points: 39,
      total_points: 497,
      rank: 7090038,
      rank_sort: 7092218,
      overall_rank: 5191474,
      bank: 13,
      value: 1001,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 12,
      points: 54,
      total_points: 539,
      rank: 3510008,
      rank_sort: 3511949,
      overall_rank: 5264018,
      bank: 2,
      value: 997,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 0,
    },
    {
      event: 13,
      points: 41,
      total_points: 580,
      rank: 4470176,
      rank_sort: 4514369,
      overall_rank: 5249648,
      bank: 1,
      value: 998,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 14,
      points: 67,
      total_points: 647,
      rank: 2682275,
      rank_sort: 2723836,
      overall_rank: 5039885,
      bank: 1,
      value: 998,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 15,
      points: 52,
      total_points: 699,
      rank: 1550702,
      rank_sort: 1580957,
      overall_rank: 4784977,
      bank: 1,
      value: 995,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 16,
      points: 27,
      total_points: 726,
      rank: 6265335,
      rank_sort: 6321829,
      overall_rank: 4922146,
      bank: 1,
      value: 997,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 17,
      points: 67,
      total_points: 793,
      rank: 1922825,
      rank_sort: 1951934,
      overall_rank: 4706252,
      bank: 14,
      value: 997,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 28,
      total_points: 821,
      rank: 4061517,
      rank_sort: 4076363,
      overall_rank: 4659509,
      bank: 5,
      value: 996,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 75,
      total_points: 896,
      rank: 3477422,
      rank_sort: 3521081,
      overall_rank: 4553880,
      bank: 5,
      value: 995,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 20,
      points: 39,
      total_points: 935,
      rank: 4458447,
      rank_sort: 4482921,
      overall_rank: 4564331,
      bank: 28,
      value: 996,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 21,
      points: 33,
      total_points: 964,
      rank: 6585014,
      rank_sort: 6587459,
      overall_rank: 4821296,
      bank: 22,
      value: 999,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 22,
      points: 65,
      total_points: 1029,
      rank: 3002049,
      rank_sort: 3035701,
      overall_rank: 4633659,
      bank: 22,
      value: 999,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 23,
      points: 40,
      total_points: 1069,
      rank: 6932990,
      rank_sort: 6936502,
      overall_rank: 4851365,
      bank: 2,
      value: 999,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 24,
      points: 59,
      total_points: 1120,
      rank: 4166363,
      rank_sort: 4167387,
      overall_rank: 4848272,
      bank: 2,
      value: 997,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 25,
      points: 75,
      total_points: 1191,
      rank: 1227111,
      rank_sort: 1245882,
      overall_rank: 4590945,
      bank: 20,
      value: 997,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 26,
      points: 82,
      total_points: 1273,
      rank: 2819195,
      rank_sort: 2836844,
      overall_rank: 4484929,
      bank: 19,
      value: 999,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 27,
      points: 78,
      total_points: 1351,
      rank: 1514490,
      rank_sort: 1535093,
      overall_rank: 4277034,
      bank: 19,
      value: 998,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 28,
      points: 35,
      total_points: 1382,
      rank: 6303181,
      rank_sort: 6306749,
      overall_rank: 4387303,
      bank: 45,
      value: 998,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 29,
      points: 12,
      total_points: 1390,
      rank: 6261579,
      rank_sort: 6264573,
      overall_rank: 4500480,
      bank: 44,
      value: 996,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 30,
      points: 19,
      total_points: 1409,
      rank: 8071382,
      rank_sort: 8077082,
      overall_rank: 4737359,
      bank: 44,
      value: 996,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 31,
      points: 34,
      total_points: 1443,
      rank: 5592263,
      rank_sort: 5662983,
      overall_rank: 4787995,
      bank: 44,
      value: 993,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 32,
      points: 29,
      total_points: 1472,
      rank: 7181639,
      rank_sort: 7221521,
      overall_rank: 4935358,
      bank: 44,
      value: 990,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 33,
      points: 13,
      total_points: 1485,
      rank: 7996389,
      rank_sort: 8011976,
      overall_rank: 5108591,
      bank: 44,
      value: 988,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 34,
      points: 59,
      total_points: 1544,
      rank: 547499,
      rank_sort: 570235,
      overall_rank: 4938670,
      bank: 44,
      value: 985,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 35,
      points: 71,
      total_points: 1615,
      rank: 5695657,
      rank_sort: 5739082,
      overall_rank: 4997988,
      bank: 44,
      value: 982,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 36,
      points: 34,
      total_points: 1649,
      rank: 4898971,
      rank_sort: 4963604,
      overall_rank: 5005649,
      bank: 44,
      value: 983,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 37,
      points: 29,
      total_points: 1678,
      rank: 7170282,
      rank_sort: 7211744,
      overall_rank: 5101901,
      bank: 44,
      value: 982,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 38,
      points: 42,
      total_points: 1720,
      rank: 5801374,
      rank_sort: 5866715,
      overall_rank: 5143518,
      bank: 44,
      value: 979,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
  ],
  past: [
    { season_name: '2016/17', total_points: 1446, rank: 3681498 },
    { season_name: '2017/18', total_points: 1833, rank: 2553459 },
    { season_name: '2018/19', total_points: 1780, rank: 4218932 },
    { season_name: '2019/20', total_points: 1786, rank: 4439944 },
  ],
  chips: [],
};
