/**
 * Imports
 */
import { GoogleOutlined } from '@ant-design/icons';
import Card from 'antd/lib/card';
import { Box, Text } from 'components';
import { MeetingData } from 'features/meetings';
import { SplashScreen } from 'features/navigation/components/SplashScreen';
import { getFirebaseInstance } from 'firebaseAPI';
import { orderBy, sortBy } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router';
import { styled } from 'theme';
const db = getFirebaseInstance();
/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
`;
const MeetingImg = styled.img`
  height: 150px;
`;

/**
 * Types
 */
export interface FBMeetingsListProps {}

/**
 * FBMeetingsList component
 */
const FBMeetingsList: React.FC<FBMeetingsListProps> = (props) => {
  const navigation = useHistory();
  const [meetings, setMeetings] = React.useState<MeetingData[]>();
  const [isFetching, setIsFetching] = React.useState(false);
  const getData = async () => {
    setIsFetching(true);

    const docRef = await db.firestore().collection('meetings').get();

    const data = docRef.docs.map((doc) => doc.data() as MeetingData);
    const filteredData = sortBy(data, (file) =>
      parseInt(file.title, 10),
    ).reverse();
    setMeetings(filteredData);
    setIsFetching(false);
  };

  const _goTo = (id: string) => {
    const path = `${ROUTES.meeting.path}/${id}`;
    navigation.push(path);
  };

  React.useEffect(() => {
    getData();
  }, []);

  if (isFetching) {
    return <SplashScreen />;
  }

  return (
    <Root>
      {meetings?.map((meeting) => {
        // const imgLink = `https://drive.google.com/file/d/${meeting.imageId}/preview`;
        const imgLink = `https://drive.google.com/uc?export=view&id=${meeting.imageId}`;
        const webLink = `https://docs.google.com/document/d/${meeting.documentId}/edit?usp=drivesdk`;
        return (
          <Box key={meeting.documentId} width={'320px'} m={'16px'}>
            <Card
              style={{ cursor: 'pointer' }}
              title={
                <Text variant="ui1" fontFamily={'heading'}>
                  {meeting.title}
                </Text>
              }
              extra={
                <a target="_blank" rel="noreferrer" href={webLink}>
                  <GoogleOutlined />
                </a>
              }
            >
              <Box onClick={() => _goTo(meeting.documentId)}>
                <Text variant="ui2" mb={'8px'}>
                  {meeting.host}
                </Text>
                <Text variant="ui3" mb={'4px'}>
                  Gave: {meeting.present}
                </Text>
                <Text variant="ui3" mb={'4px'}>
                  Mad: {meeting.food}
                </Text>
                <Text variant="ui3" mb={'16px'}>
                  Underholdning: {meeting.entertainment}
                </Text>
                {/* <iframe
                  src={imgLink}
                  width="auto"
                  height="150"
                  allow="autoplay"
                /> */}
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <MeetingImg src={imgLink} alt={meeting.title} />
                </Box>
              </Box>
            </Card>
          </Box>
        );
      })}
    </Root>
  );
};

/**
 * Exports
 */
export { FBMeetingsList };
