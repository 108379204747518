/**
 * Imports
 */
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface DocIframeProps {
  source: string;
}

/**
 * DocIframe component
 */
const DocIframe: React.FC<DocIframeProps> = (props) => {
  if (!props.source) {
    return <div>Loading...</div>;
  }
  const src = props.source;
  return (
    <Root>
      <iframe
        src={'https://docs.google.com/viewer?url=' + src + '&embedded=true'}
        title="file"
        width="100%"
        height="600"
      />
    </Root>
  );
};

/**
 * Exports
 */
export { DocIframe };
