/**
 * Project API url
 */
const getprojectApiUrl = () => {
  const hostname = window.location.hostname;
};
export const PROJECT_API_URL = getprojectApiUrl();
/**
 * Firebase environment settings
 */
const getFirebaseConfig = () => {
  return {
    apiKey: process.env['REACT_APP_API_KEY'],
    authDomain: process.env['REACT_APP_AUTH_DOMAIN'],
    projectId: process.env['REACT_APP_PROJECT_ID'],
    storageBucket: process.env['REACT_APP_STORAGE_BUCKET'],
    messagingSenderId: process.env['REACT_APP_MSG_SENDER_ID'],
    appId: process.env['REACT_APP_APP_ID'],
    measurementId: process.env['REACT_APP_MEASUREMENT_ID'],
  };
};
export const FIREBASE_CONFIG = getFirebaseConfig();
/**
 * Project environment settings
 */
const getProjectConfig = () => {};
export const PROJECT_CONFIG = getProjectConfig();
