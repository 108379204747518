export const JOHN_22 = {
  name: 'John',
  current: [
    {
      event: 1,
      points: 59,
      total_points: 59,
      rank: 4403453,
      rank_sort: 4480346,
      overall_rank: 4403446,
      bank: 25,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 2,
      points: 40,
      total_points: 91,
      rank: 6482324,
      rank_sort: 6486087,
      overall_rank: 5627435,
      bank: 5,
      value: 1002,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 17,
    },
    {
      event: 3,
      points: 57,
      total_points: 148,
      rank: 3349543,
      rank_sort: 3406543,
      overall_rank: 5140009,
      bank: 5,
      value: 1003,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 4,
      points: 52,
      total_points: 200,
      rank: 5276910,
      rank_sort: 5321831,
      overall_rank: 5126016,
      bank: 11,
      value: 1001,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 5,
      points: 37,
      total_points: 237,
      rank: 7370429,
      rank_sort: 7413954,
      overall_rank: 5649135,
      bank: 11,
      value: 998,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 6,
      points: 43,
      total_points: 280,
      rank: 4161952,
      rank_sort: 4203035,
      overall_rank: 5516694,
      bank: 3,
      value: 999,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 19,
    },
    {
      event: 7,
      points: 27,
      total_points: 307,
      rank: 7005615,
      rank_sort: 7032569,
      overall_rank: 5756419,
      bank: 4,
      value: 998,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 8,
      points: 28,
      total_points: 335,
      rank: 7926262,
      rank_sort: 7958640,
      overall_rank: 6193568,
      bank: 4,
      value: 997,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 14,
    },
    {
      event: 9,
      points: 35,
      total_points: 370,
      rank: 7357535,
      rank_sort: 7394369,
      overall_rank: 6504465,
      bank: 4,
      value: 995,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 23,
    },
    {
      event: 10,
      points: 33,
      total_points: 403,
      rank: 6808497,
      rank_sort: 6838006,
      overall_rank: 6610814,
      bank: 9,
      value: 996,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 11,
      points: 45,
      total_points: 448,
      rank: 3405169,
      rank_sort: 3492192,
      overall_rank: 6430738,
      bank: 9,
      value: 995,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 12,
    },
    {
      event: 12,
      points: 64,
      total_points: 512,
      rank: 2767371,
      rank_sort: 2824763,
      overall_rank: 6111853,
      bank: 9,
      value: 993,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 23,
    },
    {
      event: 13,
      points: 53,
      total_points: 565,
      rank: 2079047,
      rank_sort: 2138107,
      overall_rank: 5823248,
      bank: 9,
      value: 991,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 14,
      points: 57,
      total_points: 622,
      rank: 5272743,
      rank_sort: 5291412,
      overall_rank: 5752104,
      bank: 15,
      value: 993,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 15,
      points: 38,
      total_points: 660,
      rank: 5741910,
      rank_sort: 5862750,
      overall_rank: 5806610,
      bank: 15,
      value: 995,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 16,
      points: 47,
      total_points: 707,
      rank: 6225797,
      rank_sort: 6240567,
      overall_rank: 5848238,
      bank: 1,
      value: 999,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 17,
      points: 52,
      total_points: 759,
      rank: 2979277,
      rank_sort: 3024928,
      overall_rank: 5632389,
      bank: 6,
      value: 1000,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 24,
      total_points: 783,
      rank: 6974323,
      rank_sort: 7054691,
      overall_rank: 5776010,
      bank: 6,
      value: 997,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 65,
      total_points: 848,
      rank: 318931,
      rank_sort: 339669,
      overall_rank: 5250596,
      bank: 6,
      value: 998,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 20,
      points: 34,
      total_points: 882,
      rank: 4963375,
      rank_sort: 5092730,
      overall_rank: 5293184,
      bank: 4,
      value: 996,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 21,
      points: 62,
      total_points: 944,
      rank: 1554610,
      rank_sort: 1578598,
      overall_rank: 4952785,
      bank: 4,
      value: 996,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 22,
      points: 51,
      total_points: 991,
      rank: 4477179,
      rank_sort: 4488843,
      overall_rank: 4971385,
      bank: 65,
      value: 1000,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 23,
      points: 43,
      total_points: 1030,
      rank: 1921590,
      rank_sort: 1945937,
      overall_rank: 4891404,
      bank: 8,
      value: 1003,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 5,
    },
    {
      event: 24,
      points: 78,
      total_points: 1108,
      rank: 758884,
      rank_sort: 784102,
      overall_rank: 4370106,
      bank: 8,
      value: 1009,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 8,
    },
    {
      event: 25,
      points: 54,
      total_points: 1162,
      rank: 2858462,
      rank_sort: 2937183,
      overall_rank: 4227757,
      bank: 8,
      value: 1012,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 26,
      points: 56,
      total_points: 1218,
      rank: 6952984,
      rank_sort: 6957327,
      overall_rank: 4600059,
      bank: 3,
      value: 1013,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 27,
      points: 51,
      total_points: 1269,
      rank: 1917840,
      rank_sort: 1937151,
      overall_rank: 4420468,
      bank: 19,
      value: 1010,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 28,
      points: 58,
      total_points: 1327,
      rank: 5680246,
      rank_sort: 5750213,
      overall_rank: 4519891,
      bank: 19,
      value: 1008,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 29,
      points: 84,
      total_points: 1411,
      rank: 2475240,
      rank_sort: 2500703,
      overall_rank: 4326862,
      bank: 2,
      value: 1008,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 12,
    },
    {
      event: 30,
      points: 56,
      total_points: 1467,
      rank: 1175098,
      rank_sort: 1200493,
      overall_rank: 3931173,
      bank: 2,
      value: 1005,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 31,
      points: 65,
      total_points: 1528,
      rank: 512397,
      rank_sort: 519302,
      overall_rank: 3726881,
      bank: 2,
      value: 1004,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 32,
      points: 64,
      total_points: 1592,
      rank: 1634168,
      rank_sort: 1685828,
      overall_rank: 3556190,
      bank: 2,
      value: 1003,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 11,
    },
    {
      event: 33,
      points: 52,
      total_points: 1644,
      rank: 6224360,
      rank_sort: 6282932,
      overall_rank: 3714499,
      bank: 2,
      value: 1005,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 17,
    },
    {
      event: 34,
      points: 68,
      total_points: 1712,
      rank: 1268187,
      rank_sort: 1281925,
      overall_rank: 3509876,
      bank: 36,
      value: 1000,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 16,
    },
    {
      event: 35,
      points: 70,
      total_points: 1778,
      rank: 454540,
      rank_sort: 459926,
      overall_rank: 3263415,
      bank: 3,
      value: 1002,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 7,
    },
    {
      event: 36,
      points: 138,
      total_points: 1916,
      rank: 259951,
      rank_sort: 263723,
      overall_rank: 2650531,
      bank: 3,
      value: 1002,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 37,
      points: 45,
      total_points: 1957,
      rank: 3619948,
      rank_sort: 3625145,
      overall_rank: 2675571,
      bank: 0,
      value: 1005,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 7,
    },
    {
      event: 38,
      points: 59,
      total_points: 2016,
      rank: 2813528,
      rank_sort: 2880117,
      overall_rank: 2645806,
      bank: 0,
      value: 1008,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
  ],
  past: [
    {
      season_name: '2018/19',
      total_points: 2024,
      rank: 1598313,
    },
    {
      season_name: '2019/20',
      total_points: 1827,
      rank: 3970727,
    },
    {
      season_name: '2020/21',
      total_points: 2171,
      rank: 1119786,
    },
  ],
  chips: [
    {
      name: '3xc',
      time: '2021-08-25T03:46:52.425205Z',
      event: 3,
    },
    {
      name: 'freehit',
      time: '2021-12-25T11:39:12.805313Z',
      event: 19,
    },
    {
      name: 'wildcard',
      time: '2021-12-28T06:32:03.436030Z',
      event: 20,
    },
    {
      name: 'bboost',
      time: '2022-01-14T11:12:58.630435Z',
      event: 22,
    },
    {
      name: 'freehit',
      time: '2022-03-12T16:12:49.274348Z',
      event: 30,
    },
  ],
};
