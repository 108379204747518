/**
 * Imports
 */
import { ArrowRightOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { Box } from 'components';
import { GoogleLogin, GoogleSignupButton } from 'features/user/components';
import * as React from 'react';
import { styled } from 'theme';
/**
 * Styles Components
 */
const Anchor = styled.a`
  &:hover {
    color: blue;
  }
`;

/**
 * LoginPage component
 */
const LoginPage: React.FC = () => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      height="100%"
      width="100%"
      flex="1"
    >
      <Card>
        <Box minWidth={'240px'} maxWidth={'400px'} width="100%">
          <GoogleLogin />
          <Box mt="16px">
            <GoogleSignupButton />
          </Box>
          <Box display={'flex'} flexDirection={'column'} mt="24px">
            <Anchor href="https://fri13.netlify.app/" target="_blnk">
              Link til fri13.netlify.app <ArrowRightOutlined size={12} />
            </Anchor>
            <Box mt="8px" />
            <Anchor
              href="https://drive.google.com/drive/u/0/folders/0B9iHxSUXvFPuTV9uN2ZCMTdKZEk?resourcekey=0-Uk-g8mvjuZ9sm2bBXfnGXw"
              target="_blnk"
            >
              Link til Drive <ArrowRightOutlined size={12} />
            </Anchor>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

/**
 * Exports
 */
export { LoginPage };
