/**
 * Imports
 */
import { Table } from 'antd';
import { Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';
import { useFplApi } from 'features/fpl/api';

/**
 * CONST
 */

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px 0;
`;

/**
 * Types
 */
export interface SeasonTableProps {
  data: any;
  title: string;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => <Text variant="ui3">{name}</Text>,
  },
  {
    title: 'Total points',
    dataIndex: 'total_points',
    key: 'total_points',
    sorter: {
      compare: (a: any, b: any) => a.total_points - b.total_points,
    },
    render: (total_points: string) => <Text variant="ui3">{total_points}</Text>,
  },
  {
    title: 'Transfers',
    dataIndex: 'event_transfers',
    key: 'event_transfers',
    sorter: {
      compare: (a: any, b: any) => a.event_transfers - b.event_transfers,
    },
    render: (event_transfers: string) => (
      <Text variant="ui3">{event_transfers}</Text>
    ),
  },
  {
    title: 'Transfers cost',
    dataIndex: 'event_transfers_cost',
    key: 'event_transfers_cost',
    sorter: {
      compare: (a: any, b: any) =>
        a.event_transfers_cost - b.event_transfers_cost,
    },
    render: (event_transfers_cost: string) => (
      <Text variant="ui3">{event_transfers_cost}</Text>
    ),
  },
  {
    title: 'Points on bench',
    dataIndex: 'points_on_bench',
    key: 'points_on_bench',
    sorter: {
      compare: (a: any, b: any) => a.points_on_bench - b.points_on_bench,
    },
    render: (points_on_bench: string) => (
      <Text variant="ui3">{points_on_bench}</Text>
    ),
  },
  {
    title: 'Highest',
    dataIndex: 'highest',
    key: 'highest',
    sorter: {
      compare: (a: any, b: any) => a.highest - b.highest,
    },
    render: (highest: string) => <Text variant="ui3">{highest}</Text>,
  },
  {
    title: 'Lowest',
    dataIndex: 'lowest',
    key: 'lowest',
    sorter: {
      compare: (a: any, b: any) => a.lowest - b.lowest,
    },
    render: (lowest: string) => <Text variant="ui3">{lowest}</Text>,
  },
];

/**
 * SeasonTable component
 */
const SeasonTable: React.FC<SeasonTableProps> = (props) => {
  const { stats } = useFplApi(props.data);
  return (
    <Root>
      <Text variant="h5">{props.title}</Text>
      <Table scroll={{ x: 400 }} columns={columns} dataSource={stats} />
    </Root>
  );
};

/**
 * Exports
 */
export { SeasonTable };
