/**
 * Imports
 */
import { Box, Spinner, Text } from 'components';
import * as React from 'react';
/**
 * Types
 */
export interface SplashScreenProps {}

/**
 * SplashScreen component
 */
const SplashScreen: React.FC<SplashScreenProps> = (_props) => {
  return (
    <Box
      display={'flex'}
      flex="1"
      justifyContent={'center'}
      alignItems={'center'}
      height="100vh"
    >
      <Spinner showSpinner />
    </Box>
  );
};

/**
 * Exports
 */
export { SplashScreen };
