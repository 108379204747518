/**
 * Imports
 */
import * as React from 'react';
import baseStyled, {
  ThemedStyledInterface,
  ThemeProvider as BaseThemeProvider,
  createGlobalStyle,
} from 'styled-components';

import { VibrantColors } from './colors';
import { Transitions } from './transitions';

/**
 * GloblaStyle
 */
const GlobalStyle = createGlobalStyle`
  body,
  html {
    font-family: apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%
  }
  #root {
  height: 100%
}
  span {
    display: block;
  }
  /* Antd message override styling / Global snackbar */
  .vibrant-error-message .ant-message-notice-content {
    background-color:  ${VibrantColors.Message.Danger};
    color: ${VibrantColors.Text.Danger}
  }
  .vibrant-warning-message .ant-message-notice-content {
    background-color:  ${VibrantColors.Message.Warning};
    color: ${VibrantColors.Text.Warning}
  }
  .vibrant-success-message .ant-message-notice-content {
    background-color:  ${VibrantColors.Message.Success};
    color: ${VibrantColors.Text.Success}
  }
  .vibrant-info-message .ant-message-notice-content {
   background-color: ${VibrantColors.Message.Info};
   color: ${VibrantColors.Text.Info}
  }
  .ant-message-notice-content {
    font-weight: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20.8px;
    box-shadow: 0px 2px 8px rgba(20, 20, 20, 0.1);
    border-radius: 4px;
    padding: 12px 24px;
  }
  .ant-message-custom-content {
    display: flex;
    align-items: center;
  }
  .ant-message-notice-content .anticon-info-circle {
    display: none;
  }
  .ant-select-dropdown  {
    background-color: white;
  }
`;

/**
 * Theme
 */
/**
 * Theme Types
 */
export type TextSizeProps =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p1'
  | 'p2'
  | 'p3'
  | 'ui1'
  | 'ui2'
  | 'ui3';
const theme = {
  colors: VibrantColors, // used for styled system, depends on the name 'colors' for themeing
  fonts: {
    heading: 'Helvetica',
    body: 'Helvetica',
    ui: 'Helvetica',
  },
  fontSizes: {
    h1: '72px',
    h2: '64px',
    h3: '48px',
    h4: '40px',
    h5: '28px',
    h6: '21px',
    p1: '21px',
    p2: '17px',
    p3: '13px',
    ui1: '21px',
    ui2: '17px',
    ui3: '13px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 600,
    bolder: 700,
  },
  lineHeights: {
    h1: '86px',
    h2: '77px',
    h3: '58px',
    h4: '48px',
    h5: '36px',
    h6: '27px',
    p1: '34px',
    p2: '27px',
    p3: '21px',
    ui1: '21px',
    ui2: '17px',
    ui3: '13px',
  },
  // Legacy theme setup
  // Vibrant: {
  //   Colors: VibrantColors,
  //   Transitions,
  // },
};

/**
 * Types
 */
export type VibrantTheme = typeof theme;

/**
 * `styled` object for themed styled-components
 */
const styled: ThemedStyledInterface<VibrantTheme> = baseStyled;
const ThemeProvider: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
  </>
);

/**
 * Exports
 */
export { theme, styled, ThemeProvider, Transitions, VibrantColors };
