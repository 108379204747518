/**
 * Imports
 */
import { ArrowRightOutlined } from '@ant-design/icons';
import { Box, Text } from 'components';
import { BaseLayout } from 'features/navigation/components';
import { useGoogleDriveApi } from 'features/shared';
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledImg = styled.img`
  height: 100%;
  max-height: 300px;
`;
const Anchor = styled.a`
  &:hover {
    color: blue;
  }
`;

/**
 * Types
 */
export interface HomePageProps {}

/**
 * HomePage component
 */
const HomePage: React.FC<HomePageProps> = (_props) => {
  useGoogleDriveApi();

  return (
    <BaseLayout pageName="Forside">
      <Box display={'flex'} flexDirection={'column'} mb="16px">
        {window.location.hostname !== 'fri13.netlify.app' && (
          <Anchor href="https://fri13.netlify.app/" target="_blnk">
            Link til nyt site <ArrowRightOutlined size={12} />
          </Anchor>
        )}
        <Anchor
          href="https://drive.google.com/drive/u/0/folders/0B9iHxSUXvFPuTV9uN2ZCMTdKZEk?resourcekey=0-Uk-g8mvjuZ9sm2bBXfnGXw"
          target="_blnk"
        >
          Link til Drive <ArrowRightOutlined size={12} />
        </Anchor>
      </Box>
      <StyledImg
        src="https://drive.google.com/uc?export=view&id=1DJcMh5AuUkICuKnbMuT87OP3OZskeI7x"
        alt="image"
      />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { HomePage };
