export const MADSEN = {
  name: 'Madsen',
  current: [
    {
      event: 1,
      points: 35,
      total_points: 35,
      rank: 4399662,
      rank_sort: 4471253,
      overall_rank: 4399662,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 2,
      points: 66,
      total_points: 101,
      rank: 2072516,
      rank_sort: 2106595,
      overall_rank: 3418192,
      bank: 0,
      value: 998,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 3,
      points: 41,
      total_points: 134,
      rank: 3967656,
      rank_sort: 3971315,
      overall_rank: 4130672,
      bank: 5,
      value: 997,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 19,
    },
    {
      event: 4,
      points: 31,
      total_points: 149,
      rank: 5902305,
      rank_sort: 5902806,
      overall_rank: 5453619,
      bank: 3,
      value: 1000,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 21,
    },
    {
      event: 5,
      points: 59,
      total_points: 208,
      rank: 3739382,
      rank_sort: 3767815,
      overall_rank: 5166507,
      bank: 3,
      value: 1001,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 12,
    },
    {
      event: 6,
      points: 48,
      total_points: 220,
      rank: 3728611,
      rank_sort: 3728734,
      overall_rank: 6003139,
      bank: 0,
      value: 1001,
      event_transfers: 11,
      event_transfers_cost: 36,
      points_on_bench: 19,
    },
    {
      event: 7,
      points: 50,
      total_points: 266,
      rank: 4489987,
      rank_sort: 4511134,
      overall_rank: 6051554,
      bank: 2,
      value: 1003,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 8,
    },
    {
      event: 8,
      points: 70,
      total_points: 336,
      rank: 975953,
      rank_sort: 998113,
      overall_rank: 5553908,
      bank: 2,
      value: 1007,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 15,
    },
    {
      event: 9,
      points: 51,
      total_points: 387,
      rank: 4630325,
      rank_sort: 4665183,
      overall_rank: 5496736,
      bank: 2,
      value: 1013,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 10,
      points: 39,
      total_points: 426,
      rank: 4936569,
      rank_sort: 4988381,
      overall_rank: 5498310,
      bank: 2,
      value: 1014,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 11,
      points: 77,
      total_points: 503,
      rank: 1134186,
      rank_sort: 1158976,
      overall_rank: 5065406,
      bank: 2,
      value: 1015,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 12,
      points: 56,
      total_points: 559,
      rank: 2956224,
      rank_sort: 2992800,
      overall_rank: 4868753,
      bank: 6,
      value: 1014,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 13,
      points: 44,
      total_points: 603,
      rank: 3354937,
      rank_sort: 3426911,
      overall_rank: 4807556,
      bank: 6,
      value: 1016,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 14,
      points: 47,
      total_points: 650,
      rank: 5925892,
      rank_sort: 5964821,
      overall_rank: 4986677,
      bank: 6,
      value: 1017,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 15,
      points: 54,
      total_points: 704,
      rank: 1222043,
      rank_sort: 1248646,
      overall_rank: 4692954,
      bank: 6,
      value: 1017,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 16,
      points: 41,
      total_points: 745,
      rank: 2944127,
      rank_sort: 3013018,
      overall_rank: 4588964,
      bank: 6,
      value: 1020,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 17,
      points: 55,
      total_points: 800,
      rank: 4210172,
      rank_sort: 4265927,
      overall_rank: 4587215,
      bank: 6,
      value: 1021,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 18,
      points: 38,
      total_points: 838,
      rank: 2281204,
      rank_sort: 2321504,
      overall_rank: 4370017,
      bank: 6,
      value: 1023,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 61,
      total_points: 899,
      rank: 5723400,
      rank_sort: 5768710,
      overall_rank: 4505937,
      bank: 6,
      value: 1021,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 20,
      points: 42,
      total_points: 941,
      rank: 3600725,
      rank_sort: 3654942,
      overall_rank: 4469035,
      bank: 6,
      value: 1021,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 21,
      points: 56,
      total_points: 997,
      rank: 2539595,
      rank_sort: 2574148,
      overall_rank: 4332687,
      bank: 6,
      value: 1022,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 22,
      points: 81,
      total_points: 1078,
      rank: 986514,
      rank_sort: 1009336,
      overall_rank: 3927678,
      bank: 6,
      value: 1020,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 23,
      points: 75,
      total_points: 1153,
      rank: 1096064,
      rank_sort: 1125818,
      overall_rank: 3714271,
      bank: 6,
      value: 1023,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 24,
      points: 59,
      total_points: 1212,
      rank: 4140842,
      rank_sort: 4152307,
      overall_rank: 3702242,
      bank: 8,
      value: 1023,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 25,
      points: 77,
      total_points: 1285,
      rank: 1011376,
      rank_sort: 1029180,
      overall_rank: 3464925,
      bank: 2,
      value: 1024,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 1,
    },
    {
      event: 26,
      points: 86,
      total_points: 1371,
      rank: 2144569,
      rank_sort: 2160447,
      overall_rank: 3320576,
      bank: 4,
      value: 1024,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 18,
    },
    {
      event: 27,
      points: 68,
      total_points: 1435,
      rank: 2819503,
      rank_sort: 2831217,
      overall_rank: 3306451,
      bank: 9,
      value: 1027,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 14,
    },
    {
      event: 28,
      points: 43,
      total_points: 1478,
      rank: 4085367,
      rank_sort: 4154111,
      overall_rank: 3314578,
      bank: 9,
      value: 1030,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 29,
      points: 38,
      total_points: 1516,
      rank: 1851848,
      rank_sort: 1869006,
      overall_rank: 3166092,
      bank: 0,
      value: 1024,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 30,
      points: 65,
      total_points: 1581,
      rank: 600747,
      rank_sort: 624364,
      overall_rank: 2954060,
      bank: 0,
      value: 1026,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 31,
      points: 53,
      total_points: 1634,
      rank: 1596865,
      rank_sort: 1613353,
      overall_rank: 2829245,
      bank: 11,
      value: 1029,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 11,
    },
    {
      event: 32,
      points: 63,
      total_points: 1697,
      rank: 1321929,
      rank_sort: 1344768,
      overall_rank: 2654904,
      bank: 11,
      value: 1029,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 8,
    },
    {
      event: 33,
      points: 32,
      total_points: 1729,
      rank: 4839314,
      rank_sort: 4917047,
      overall_rank: 2700952,
      bank: 11,
      value: 1031,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 34,
      points: 59,
      total_points: 1788,
      rank: 547499,
      rank_sort: 570175,
      overall_rank: 2495975,
      bank: 11,
      value: 1031,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 35,
      points: 51,
      total_points: 1839,
      rank: 7639370,
      rank_sort: 7656673,
      overall_rank: 2852102,
      bank: 11,
      value: 1028,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 26,
    },
    {
      event: 36,
      points: 39,
      total_points: 1878,
      rank: 3827918,
      rank_sort: 3890212,
      overall_rank: 2862547,
      bank: 11,
      value: 1027,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 37,
      points: 47,
      total_points: 1921,
      rank: 3456465,
      rank_sort: 3460233,
      overall_rank: 2904998,
      bank: 6,
      value: 1023,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 10,
    },
    {
      event: 38,
      points: 43,
      total_points: 1964,
      rank: 5579727,
      rank_sort: 5647295,
      overall_rank: 2969934,
      bank: 6,
      value: 1021,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
  ],
  past: [
    { season_name: '2016/17', total_points: 1477, rank: 3587744 },
    { season_name: '2017/18', total_points: 1686, rank: 3672710 },
    { season_name: '2018/19', total_points: 1850, rank: 3621642 },
    { season_name: '2019/20', total_points: 1789, rank: 4403243 },
  ],
  chips: [
    { name: 'bboost', time: '2020-09-09T18:06:21.325489Z', event: 1 },
    { name: '3xc', time: '2020-10-19T21:20:20.526420Z', event: 6 },
    { name: 'freehit', time: '2021-02-02T09:27:59.306256Z', event: 22 },
  ],
};
