/**
 * Imports
 */
import { Tabs } from 'antd';
import {
  BALDER,
  BALDER_22,
  DANIEL,
  DANIEL_22,
  JOHN,
  JOHN_22,
  KRAG,
  KRAG_22,
  MADSEN,
  MADSEN_22,
  SMIDT,
  SMIDT_22,
} from 'apis';
import { OverallTable, SeasonTable } from 'features/fpl';
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { styled } from 'theme';
const { TabPane } = Tabs;

const DATA_20 = [BALDER, DANIEL, SMIDT, JOHN, KRAG, MADSEN];
const DATA_22 = [MADSEN_22, SMIDT_22, JOHN_22, BALDER_22, DANIEL_22, KRAG_22];
/**
 * Styling
 */
const Container = styled.div`
  overflow: scroll;
`;

/**
 * Types
 */
export interface FPLOverviewPageProps {}

/**
 * FPLOverviewPage component
 */
const FPLOverviewPage: React.FC<FPLOverviewPageProps> = (props) => {
  return (
    <BaseLayout pageName="FPL">
      <Container>
        <Tabs defaultActiveKey="1">
          <TabPane tab="21/22" key="1">
            <SeasonTable title="Season 2021/2022" data={DATA_22} />
          </TabPane>
          <TabPane tab="20/21" key="2">
            <SeasonTable title="Season 2020/2021" data={DATA_20} />
          </TabPane>
          <TabPane tab="Overall" key="3">
            <OverallTable title="Overall" data={DATA_22} />
          </TabPane>
        </Tabs>
      </Container>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { FPLOverviewPage };
