/**
 * Imports
 */
import { Card, Tabs } from 'antd';
import { Box, Text } from 'components';
import { BaseLayout } from 'features/navigation/components';
import { SplashScreen } from 'features/navigation/components/SplashScreen';
import { MemberData } from 'features/user/components';
import { getFirebaseInstance } from 'firebaseAPI';
import { orderBy } from 'lodash';
import * as React from 'react';
import { styled } from 'theme';
const { TabPane } = Tabs;
const db = getFirebaseInstance();
/**
 * Styling
 */
const StyledFrame = styled.iframe`
  border: none;
  width: 100%;
  min-height: calc(100vh - 200px);
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  @media (max-width: 600px) {
    min-height: 800px;
  }
`;
/**
 * Types
 */
export interface MaterialsPageProps {}
const LINK =
  'https://drive.google.com/file/d/1vjGsTukmIeVAmOXr70IV29CNhcAIR0Nd9WjKPuwL22k/preview';
/**
 * MaterialsPage component
 */
const MaterialsPage: React.FC<MaterialsPageProps> = (props) => {
  const [details, setDetails] = React.useState<MemberData[]>();
  const [isFetching, setIsFetching] = React.useState(false);
  const getData = async () => {
    setIsFetching(true);
    const docRef = await db.firestore().collection('users').get();

    const data = docRef.docs.map((doc) => doc.data() as MemberData);
    const filteredData = orderBy(data, 'name', ['asc']);
    setDetails(filteredData);
    setIsFetching(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  if (isFetching) {
    return <SplashScreen />;
  }

  return (
    <BaseLayout pageName="Materiale">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Logesangen" key="1">
          <StyledFrame src={LINK} />
        </TabPane>
        <TabPane tab="Medlemmer" key="2">
          {details?.map((member) => {
            return (
              <Box key={member.name} mt="16px">
                <Card>
                  <Box>
                    <Text variant="ui1" mb="8px">
                      {member.name}
                    </Text>
                    <Text variant="ui2" mb="4px">
                      Addresse: {member.address}
                    </Text>
                    <Text variant="ui2" mb="4px">
                      Fødselsdato: {member.birthdate}
                    </Text>
                    <Text variant="ui2" mb="4px">
                      E-mail: {member.mail}
                    </Text>
                    <Text variant="ui2" mb="4px">
                      Telefon: {member.phone}
                    </Text>
                    <Text variant="ui2" mb="4px">
                      Pas og udløbsdato: {member.passport}
                    </Text>
                  </Box>
                </Card>
              </Box>
            );
          })}
        </TabPane>
      </Tabs>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { MaterialsPage };
