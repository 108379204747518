/**
 * Imports
 */
import { Card, List } from 'antd';
import { Box, Button, Text } from 'components';
import { BaseLayout } from 'features/navigation/components';
import { AccountDetails, MemberData } from 'features/user/components';
import { isAdminSelector, userSelector } from 'features/user/redux/selectors';
import { getFirebaseInstance } from 'firebaseAPI';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
const db = getFirebaseInstance();
import { styled } from 'theme';

/**
 * Styling
 */
const Content = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface AccountPageProps {}

/**psideba
 * AccountPage component
 */
const AccountPage: React.FC<AccountPageProps> = (props) => {
  const isAdmin = useSelector(isAdminSelector);
  const [details, setDetails] = React.useState<MemberData[]>();
  const [isFetching, setIsFetching] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const getData = async () => {
    setIsFetching(true);
    const docRef = await db.firestore().collection('users').get();

    const data = docRef.docs.map((doc) => doc.data() as MemberData);
    const filteredData = orderBy(data, 'name', ['asc']);
    setDetails(filteredData);
    setIsFetching(false);
  };

  const _deleteUser = async (id: string) => {
    setIsDeleting(true);
    await db.firestore().collection('users').doc(id).delete();
    setIsDeleting(false);
    getData();
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <BaseLayout pageName="Medlems info">
      <AccountDetails />
      {isAdmin && (
        <Box width={'100%'} maxWidth={'450px'} mt="32px">
          <Card>
            <List>
              {details?.map((d) => {
                return (
                  <List.Item key={d.name}>
                    <Text variant="ui3">
                      {d.name}: {d.userId} - {d.phone || 'n/a'}
                    </Text>
                    <Button
                      loading={isDeleting}
                      type="link"
                      onClick={() => _deleteUser(d.userId)}
                    >
                      Slet
                    </Button>
                  </List.Item>
                );
              })}
            </List>
          </Card>
        </Box>
      )}
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { AccountPage };
