export const SMIDT_22 = {
  name: 'Smidt',
  current: [
    {
      event: 1,
      points: 96,
      total_points: 96,
      rank: 633476,
      rank_sort: 677244,
      overall_rank: 633473,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 2,
      points: 73,
      total_points: 169,
      rank: 989758,
      rank_sort: 1005246,
      overall_rank: 474507,
      bank: 0,
      value: 1004,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 3,
      points: 51,
      total_points: 216,
      rank: 4807450,
      rank_sort: 4823584,
      overall_rank: 1040737,
      bank: 2,
      value: 1011,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 5,
    },
    {
      event: 4,
      points: 69,
      total_points: 281,
      rank: 1517544,
      rank_sort: 1531573,
      overall_rank: 860295,
      bank: 6,
      value: 1012,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 3,
    },
    {
      event: 5,
      points: 65,
      total_points: 342,
      rank: 1872966,
      rank_sort: 1886183,
      overall_rank: 838111,
      bank: 7,
      value: 1012,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 7,
    },
    {
      event: 6,
      points: 39,
      total_points: 381,
      rank: 5174627,
      rank_sort: 5215016,
      overall_rank: 1082356,
      bank: 7,
      value: 1016,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 7,
      points: 49,
      total_points: 430,
      rank: 1239655,
      rank_sort: 1263140,
      overall_rank: 882190,
      bank: 0,
      value: 1014,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 11,
    },
    {
      event: 8,
      points: 59,
      total_points: 489,
      rank: 1701265,
      rank_sort: 1733686,
      overall_rank: 779441,
      bank: 0,
      value: 1022,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 14,
    },
    {
      event: 9,
      points: 100,
      total_points: 585,
      rank: 440998,
      rank_sort: 442801,
      overall_rank: 500840,
      bank: 2,
      value: 1025,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 18,
    },
    {
      event: 10,
      points: 53,
      total_points: 630,
      rank: 1739081,
      rank_sort: 1744095,
      overall_rank: 514650,
      bank: 1,
      value: 1028,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 7,
    },
    {
      event: 11,
      points: 59,
      total_points: 689,
      rank: 836270,
      rank_sort: 853122,
      overall_rank: 417690,
      bank: 5,
      value: 1030,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 12,
      points: 69,
      total_points: 754,
      rank: 1948444,
      rank_sort: 1964793,
      overall_rank: 444820,
      bank: 5,
      value: 1035,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 16,
    },
    {
      event: 13,
      points: 60,
      total_points: 810,
      rank: 1025254,
      rank_sort: 1038707,
      overall_rank: 397054,
      bank: 24,
      value: 1037,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 10,
    },
    {
      event: 14,
      points: 76,
      total_points: 874,
      rank: 957829,
      rank_sort: 958441,
      overall_rank: 421479,
      bank: 2,
      value: 1040,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 5,
    },
    {
      event: 15,
      points: 53,
      total_points: 927,
      rank: 1555113,
      rank_sort: 1584436,
      overall_rank: 376870,
      bank: 2,
      value: 1046,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 7,
    },
    {
      event: 16,
      points: 79,
      total_points: 1002,
      rank: 622165,
      rank_sort: 627605,
      overall_rank: 306190,
      bank: 0,
      value: 1047,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 8,
    },
    {
      event: 17,
      points: 71,
      total_points: 1065,
      rank: 344843,
      rank_sort: 346900,
      overall_rank: 268237,
      bank: 14,
      value: 1052,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 3,
    },
    {
      event: 18,
      points: 60,
      total_points: 1121,
      rank: 951931,
      rank_sort: 964466,
      overall_rank: 250360,
      bank: 21,
      value: 1050,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 36,
      total_points: 1157,
      rank: 4232248,
      rank_sort: 4267927,
      overall_rank: 307096,
      bank: 21,
      value: 1054,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 20,
      points: 24,
      total_points: 1173,
      rank: 7721642,
      rank_sort: 7722020,
      overall_rank: 471574,
      bank: 15,
      value: 1050,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 21,
      points: 83,
      total_points: 1256,
      rank: 320681,
      rank_sort: 327447,
      overall_rank: 344214,
      bank: 15,
      value: 1049,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 14,
    },
    {
      event: 22,
      points: 55,
      total_points: 1303,
      rank: 3601046,
      rank_sort: 3608127,
      overall_rank: 413851,
      bank: 96,
      value: 1045,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 9,
    },
    {
      event: 23,
      points: 33,
      total_points: 1332,
      rank: 5208931,
      rank_sort: 5228010,
      overall_rank: 453443,
      bank: 9,
      value: 1048,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 5,
    },
    {
      event: 24,
      points: 81,
      total_points: 1409,
      rank: 592442,
      rank_sort: 598674,
      overall_rank: 396719,
      bank: 8,
      value: 1054,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 3,
    },
    {
      event: 25,
      points: 61,
      total_points: 1470,
      rank: 1764775,
      rank_sort: 1777078,
      overall_rank: 383083,
      bank: 22,
      value: 1056,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 26,
      points: 121,
      total_points: 1579,
      rank: 1403967,
      rank_sort: 1405243,
      overall_rank: 427772,
      bank: 4,
      value: 1052,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 14,
    },
    {
      event: 27,
      points: 38,
      total_points: 1613,
      rank: 5082853,
      rank_sort: 5095854,
      overall_rank: 496435,
      bank: 36,
      value: 1045,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 28,
      points: 102,
      total_points: 1715,
      rank: 855068,
      rank_sort: 864012,
      overall_rank: 413503,
      bank: 11,
      value: 1039,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 16,
    },
    {
      event: 29,
      points: 110,
      total_points: 1809,
      rank: 163214,
      rank_sort: 163573,
      overall_rank: 379204,
      bank: 14,
      value: 1042,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 15,
    },
    {
      event: 30,
      points: 50,
      total_points: 1859,
      rank: 1673415,
      rank_sort: 1682226,
      overall_rank: 379770,
      bank: 3,
      value: 1043,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 31,
      points: 49,
      total_points: 1908,
      rank: 2791298,
      rank_sort: 2837697,
      overall_rank: 377920,
      bank: 3,
      value: 1047,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 32,
      points: 48,
      total_points: 1956,
      rank: 4326297,
      rank_sort: 4366882,
      overall_rank: 428083,
      bank: 3,
      value: 1047,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 22,
    },
    {
      event: 33,
      points: 71,
      total_points: 2027,
      rank: 3090024,
      rank_sort: 3101892,
      overall_rank: 413836,
      bank: 7,
      value: 1044,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 16,
    },
    {
      event: 34,
      points: 94,
      total_points: 2109,
      rank: 62029,
      rank_sort: 62141,
      overall_rank: 323811,
      bank: 1,
      value: 1040,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 10,
    },
    {
      event: 35,
      points: 42,
      total_points: 2151,
      rank: 4776157,
      rank_sort: 4830563,
      overall_rank: 336803,
      bank: 1,
      value: 1042,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 36,
      points: 85,
      total_points: 2228,
      rank: 3355408,
      rank_sort: 3356625,
      overall_rank: 426804,
      bank: 0,
      value: 1038,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 37,
      points: 87,
      total_points: 2315,
      rank: 437219,
      rank_sort: 442904,
      overall_rank: 354799,
      bank: 0,
      value: 1038,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 38,
      points: 74,
      total_points: 2381,
      rank: 1035306,
      rank_sort: 1040374,
      overall_rank: 364109,
      bank: 4,
      value: 1036,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 5,
    },
  ],
  past: [
    {
      season_name: '2016/17',
      total_points: 1844,
      rank: 1535183,
    },
    {
      season_name: '2017/18',
      total_points: 2097,
      rank: 508452,
    },
    {
      season_name: '2018/19',
      total_points: 2211,
      rank: 235117,
    },
    {
      season_name: '2019/20',
      total_points: 2153,
      rank: 534988,
    },
    {
      season_name: '2020/21',
      total_points: 2085,
      rank: 1832457,
    },
  ],
  chips: [
    {
      name: 'wildcard',
      time: '2021-10-01T09:38:47.097159Z',
      event: 7,
    },
    {
      name: '3xc',
      time: '2021-12-14T17:55:24.993499Z',
      event: 17,
    },
    {
      name: 'freehit',
      time: '2022-01-01T10:37:01.784116Z',
      event: 21,
    },
    {
      name: 'wildcard',
      time: '2022-03-05T10:33:33.285495Z',
      event: 28,
    },
    {
      name: 'bboost',
      time: '2022-05-06T19:56:20.272808Z',
      event: 36,
    },
    {
      name: 'freehit',
      time: '2022-05-13T07:11:20.206165Z',
      event: 37,
    },
  ],
};
