/**
 * Imports
 */
import { Modal, Select } from 'antd';
import { Box, Button, Input, Text } from 'components';
import { GoogleDriveDocumentType } from 'features/meetings';
import { MemberData } from 'features/user/components';
import { getFirebaseInstance } from 'firebaseAPI';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

const db = getFirebaseInstance();
/**
 * Types
 */
export type MeetingData = {
  title: string;
  documentId: string;
  imageId: string;
  entertainment: string;
  host: string;
  present: string;
  food: string;
};
export interface AddMeetingProps {
  documents: GoogleDriveDocumentType[];
  images: GoogleDriveDocumentType[];
  members: MemberData[];
}

/**
 * AddMeeting component
 */
const AddMeeting: React.FC<AddMeetingProps> = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const { formState, control, handleSubmit, reset } = useForm<MeetingData>({});
  const _addNewMeeting = async (data: MeetingData) => {
    setIsSaving(true);
    await db.firestore().collection('meetings').doc(data.documentId).set(data);
    setIsSaving(false);
    reset();
  };
  const _showModal = () => {
    setShowModal(true);
  };
  const _hideModal = () => {
    setShowModal(false);
  };

  const onSubmit = handleSubmit(
    (data) => {
      // Send to firebase
      for (const key in data) {
        // @ts-ignore
        if (data[key] === undefined) {
          // @ts-ignore
          delete data[key];
        }
      }
      _addNewMeeting(data);
    },
    ({ documentId }) => {
      console.log('error', { documentId });
    },
  );

  return (
    <Box>
      <Button type="ghost" onClick={_showModal}>
        +
      </Button>
      <Modal visible={showModal} onCancel={_hideModal} footer={null}>
        <Text variant="h5" mb="24px">
          Tilføj møde
        </Text>
        <form onSubmit={onSubmit}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Select
                style={{ width: '100%' }}
                {...field}
                placeholder={'Titel'}
              >
                {props.documents.map((doc) => {
                  return (
                    <Select.Option key={doc.id} value={doc.name}>
                      {doc.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          />
          <Box mt="16px" />
          <Controller
            name="documentId"
            control={control}
            render={({ field }) => (
              <Select
                style={{ width: '100%' }}
                {...field}
                placeholder={'Dokument'}
              >
                {props.documents.map((doc) => {
                  return (
                    <Select.Option key={doc.id} value={doc.id}>
                      {doc.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          />

          <Box mt="16px" />
          {/* TODO: Get list from members */}
          <Controller
            name="host"
            control={control}
            render={({ field }) => (
              <Select style={{ width: '100%' }} {...field} placeholder={'Vært'}>
                {props.members.map((member) => {
                  return (
                    <Select.Option key={member.name} value={member.name}>
                      {member.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          />
          <Box mt="16px" />
          <Controller
            name="food"
            control={control}
            render={({ field }) => <Input placeholder={'Mad'} {...field} />}
          />
          <Box mt="16px" />
          <Controller
            name="present"
            control={control}
            render={({ field }) => <Input placeholder={'Gave'} {...field} />}
          />
          <Box mt="16px" />
          <Controller
            name="entertainment"
            control={control}
            render={({ field }) => (
              <Input placeholder={'Underholdning'} {...field} />
            )}
          />

          <Box mt="16px" />
          <Controller
            name="imageId"
            control={control}
            render={({ field }) => (
              <Select
                style={{ width: '100%' }}
                {...field}
                placeholder={'Billede'}
              >
                {props.images.map((image) => {
                  return (
                    <Select.Option key={image.id} value={image.id}>
                      {image.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          />
          <Box mt="16px" />
          <Button
            type="default"
            loading={isSaving}
            disabled={formState.isSubmitting}
            style={{ borderRadius: '28px' }}
            onClick={onSubmit}
          >
            Gem
          </Button>
        </form>
      </Modal>
    </Box>
  );
};

/**
 * Exports
 */
export { AddMeeting };
