export const BALDER_22 = {
  name: 'Balder',
  current: [
    {
      event: 1,
      points: 69,
      total_points: 69,
      rank: 3436438,
      rank_sort: 3508224,
      overall_rank: 3436433,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 2,
      points: 64,
      total_points: 129,
      rank: 2251152,
      rank_sort: 2261552,
      overall_rank: 2785962,
      bank: 4,
      value: 1001,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 3,
    },
    {
      event: 3,
      points: 60,
      total_points: 189,
      rank: 2650796,
      rank_sort: 2695330,
      overall_rank: 2450390,
      bank: 11,
      value: 1003,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 8,
    },
    {
      event: 4,
      points: 46,
      total_points: 231,
      rank: 6501049,
      rank_sort: 6516055,
      overall_rank: 3389314,
      bank: 1,
      value: 1005,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 9,
    },
    {
      event: 5,
      points: 81,
      total_points: 308,
      rank: 182062,
      rank_sort: 185669,
      overall_rank: 2212764,
      bank: 7,
      value: 1003,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 1,
    },
    {
      event: 6,
      points: 51,
      total_points: 351,
      rank: 1931865,
      rank_sort: 1938995,
      overall_rank: 2287268,
      bank: 0,
      value: 1006,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 9,
    },
    {
      event: 7,
      points: 42,
      total_points: 393,
      rank: 2792162,
      rank_sort: 2853570,
      overall_rank: 2226714,
      bank: 0,
      value: 1006,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 8,
      points: 42,
      total_points: 431,
      rank: 6023100,
      rank_sort: 6026545,
      overall_rank: 2768064,
      bank: 6,
      value: 1005,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 5,
    },
    {
      event: 9,
      points: 54,
      total_points: 477,
      rank: 5529955,
      rank_sort: 5534281,
      overall_rank: 3527167,
      bank: 11,
      value: 1006,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 15,
    },
    {
      event: 10,
      points: 50,
      total_points: 527,
      rank: 2161608,
      rank_sort: 2210659,
      overall_rank: 3257625,
      bank: 11,
      value: 1006,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 11,
      points: 63,
      total_points: 590,
      rank: 491574,
      rank_sort: 503093,
      overall_rank: 2679784,
      bank: 0,
      value: 1002,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 11,
    },
    {
      event: 12,
      points: 68,
      total_points: 654,
      rank: 2128249,
      rank_sort: 2153420,
      overall_rank: 2537315,
      bank: 3,
      value: 1004,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 6,
    },
    {
      event: 13,
      points: 56,
      total_points: 706,
      rank: 1635387,
      rank_sort: 1659493,
      overall_rank: 2361975,
      bank: 1,
      value: 1005,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 14,
      points: 67,
      total_points: 757,
      rank: 2753608,
      rank_sort: 2754254,
      overall_rank: 2681565,
      bank: 6,
      value: 1006,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 6,
    },
    {
      event: 15,
      points: 53,
      total_points: 810,
      rank: 1555113,
      rank_sort: 1606772,
      overall_rank: 2470591,
      bank: 6,
      value: 1009,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 12,
    },
    {
      event: 16,
      points: 76,
      total_points: 882,
      rank: 923002,
      rank_sort: 933038,
      overall_rank: 2176848,
      bank: 5,
      value: 1012,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 17,
      points: 36,
      total_points: 914,
      rank: 6507868,
      rank_sort: 6516909,
      overall_rank: 2555975,
      bank: 11,
      value: 1012,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 43,
      total_points: 953,
      rank: 3641632,
      rank_sort: 3659698,
      overall_rank: 2604147,
      bank: 6,
      value: 1009,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 61,
      total_points: 1002,
      rank: 587559,
      rank_sort: 588579,
      overall_rank: 2372101,
      bank: 5,
      value: 1007,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: -1,
    },
    {
      event: 20,
      points: 76,
      total_points: 1078,
      rank: 15166,
      rank_sort: 16583,
      overall_rank: 1652708,
      bank: 5,
      value: 1008,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 21,
      points: 57,
      total_points: 1135,
      rank: 2012366,
      rank_sort: 2021063,
      overall_rank: 1590270,
      bank: 9,
      value: 1011,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 22,
      points: 51,
      total_points: 1178,
      rank: 4491486,
      rank_sort: 4494907,
      overall_rank: 1757820,
      bank: 8,
      value: 1009,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 6,
    },
    {
      event: 23,
      points: 36,
      total_points: 1210,
      rank: 4102970,
      rank_sort: 4131661,
      overall_rank: 1808193,
      bank: 1,
      value: 1015,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 24,
      points: 74,
      total_points: 1284,
      rank: 1113099,
      rank_sort: 1139758,
      overall_rank: 1599279,
      bank: 1,
      value: 1020,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 25,
      points: 66,
      total_points: 1350,
      rank: 1144114,
      rank_sort: 1159017,
      overall_rank: 1410358,
      bank: 5,
      value: 1018,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 26,
      points: 94,
      total_points: 1436,
      rank: 3634349,
      rank_sort: 3638212,
      overall_rank: 1602912,
      bank: 2,
      value: 1017,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 12,
    },
    {
      event: 27,
      points: 46,
      total_points: 1474,
      rank: 3014363,
      rank_sort: 3024119,
      overall_rank: 1656177,
      bank: 29,
      value: 1014,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 10,
    },
    {
      event: 28,
      points: 115,
      total_points: 1589,
      rank: 358799,
      rank_sort: 366176,
      overall_rank: 1270820,
      bank: 29,
      value: 1010,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 16,
    },
    {
      event: 29,
      points: 59,
      total_points: 1644,
      rank: 6917076,
      rank_sort: 6921090,
      overall_rank: 1512648,
      bank: 42,
      value: 1003,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 3,
    },
    {
      event: 30,
      points: 55,
      total_points: 1691,
      rank: 1296097,
      rank_sort: 1301221,
      overall_rank: 1453082,
      bank: 46,
      value: 1000,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 31,
      points: 52,
      total_points: 1735,
      rank: 2301679,
      rank_sort: 2307886,
      overall_rank: 1479783,
      bank: 4,
      value: 1001,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 1,
    },
    {
      event: 32,
      points: 74,
      total_points: 1809,
      rank: 704139,
      rank_sort: 724600,
      overall_rank: 1323139,
      bank: 4,
      value: 1002,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 33,
      points: 46,
      total_points: 1855,
      rank: 7153584,
      rank_sort: 7160447,
      overall_rank: 1489402,
      bank: 11,
      value: 1004,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 34,
      points: 66,
      total_points: 1913,
      rank: 1545907,
      rank_sort: 1547631,
      overall_rank: 1445893,
      bank: 9,
      value: 1002,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 7,
    },
    {
      event: 35,
      points: 70,
      total_points: 1983,
      rank: 413355,
      rank_sort: 429972,
      overall_rank: 1251680,
      bank: 9,
      value: 1003,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 36,
      points: 115,
      total_points: 2098,
      rank: 863400,
      rank_sort: 873503,
      overall_rank: 1089383,
      bank: 9,
      value: 1002,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 37,
      points: 25,
      total_points: 2123,
      rank: 7356299,
      rank_sort: 7423013,
      overall_rank: 1285581,
      bank: 9,
      value: 1004,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 38,
      points: 63,
      total_points: 2186,
      rank: 2241474,
      rank_sort: 2283708,
      overall_rank: 1292133,
      bank: 9,
      value: 999,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
  ],
  past: [
    {
      season_name: '2016/17',
      total_points: 1999,
      rank: 609223,
    },
    {
      season_name: '2017/18',
      total_points: 1883,
      rank: 2157368,
    },
    {
      season_name: '2018/19',
      total_points: 2002,
      rank: 1862916,
    },
    {
      season_name: '2019/20',
      total_points: 1879,
      rank: 3343379,
    },
    {
      season_name: '2020/21',
      total_points: 2017,
      rank: 2461378,
    },
  ],
  chips: [
    {
      name: '3xc',
      time: '2021-10-30T05:33:58.552208Z',
      event: 10,
    },
    {
      name: 'freehit',
      time: '2022-03-05T05:57:07.086395Z',
      event: 28,
    },
    {
      name: 'wildcard',
      time: '2022-05-20T09:41:20.493844Z',
      event: 38,
    },
  ],
};
