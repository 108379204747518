export const KRAG = {
  name: 'Krag',
  current: [
    {
      event: 1,
      points: 68,
      total_points: 68,
      rank: 984401,
      rank_sort: 996396,
      overall_rank: 984401,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 2,
      points: 59,
      total_points: 127,
      rank: 3082270,
      rank_sort: 3088468,
      overall_rank: 1256315,
      bank: 5,
      value: 1000,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 13,
    },
    {
      event: 3,
      points: 54,
      total_points: 181,
      rank: 1246363,
      rank_sort: 1251238,
      overall_rank: 726049,
      bank: 5,
      value: 1001,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 4,
      points: 37,
      total_points: 214,
      rank: 5186129,
      rank_sort: 5188921,
      overall_rank: 1844644,
      bank: 25,
      value: 1006,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 5,
      points: 64,
      total_points: 274,
      rank: 3037195,
      rank_sort: 3042157,
      overall_rank: 1953999,
      bank: 27,
      value: 1005,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 12,
    },
    {
      event: 6,
      points: 47,
      total_points: 317,
      rank: 3892800,
      rank_sort: 3898631,
      overall_rank: 2284927,
      bank: 0,
      value: 1011,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 15,
    },
    {
      event: 7,
      points: 46,
      total_points: 363,
      rank: 5208220,
      rank_sort: 5216680,
      overall_rank: 2647877,
      bank: 0,
      value: 1012,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 8,
      points: 55,
      total_points: 414,
      rank: 3897501,
      rank_sort: 3898839,
      overall_rank: 2896526,
      bank: 0,
      value: 1016,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 16,
    },
    {
      event: 9,
      points: 62,
      total_points: 476,
      rank: 2568163,
      rank_sort: 2573791,
      overall_rank: 2680783,
      bank: 0,
      value: 1019,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 10,
      points: 51,
      total_points: 527,
      rank: 2187144,
      rank_sort: 2194654,
      overall_rank: 2468968,
      bank: 0,
      value: 1023,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 11,
      points: 85,
      total_points: 612,
      rank: 421468,
      rank_sort: 423801,
      overall_rank: 1803577,
      bank: 0,
      value: 1029,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 7,
    },
    {
      event: 12,
      points: 70,
      total_points: 682,
      rank: 552928,
      rank_sort: 556686,
      overall_rank: 1408715,
      bank: 0,
      value: 1025,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 13,
      points: 42,
      total_points: 720,
      rank: 4229102,
      rank_sort: 4236511,
      overall_rank: 1608437,
      bank: 5,
      value: 1029,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 14,
      points: 60,
      total_points: 776,
      rank: 3960123,
      rank_sort: 3962793,
      overall_rank: 1890817,
      bank: 1,
      value: 1033,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 15,
      points: 43,
      total_points: 811,
      rank: 3624502,
      rank_sort: 3626619,
      overall_rank: 2144080,
      bank: 0,
      value: 1035,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 10,
    },
    {
      event: 16,
      points: 24,
      total_points: 835,
      rank: 6758779,
      rank_sort: 6764963,
      overall_rank: 2562806,
      bank: 0,
      value: 1035,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 17,
      points: 81,
      total_points: 912,
      rank: 305557,
      rank_sort: 305808,
      overall_rank: 2156765,
      bank: 0,
      value: 1034,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 42,
      total_points: 938,
      rank: 1899597,
      rank_sort: 1899842,
      overall_rank: 2311520,
      bank: 14,
      value: 1032,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 77,
      total_points: 1007,
      rank: 3314849,
      rank_sort: 3315728,
      overall_rank: 2497417,
      bank: 0,
      value: 1032,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 6,
    },
    {
      event: 20,
      points: 41,
      total_points: 1048,
      rank: 3835090,
      rank_sort: 3843861,
      overall_rank: 2511238,
      bank: 1,
      value: 1028,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 21,
      points: 67,
      total_points: 1111,
      rank: 1153108,
      rank_sort: 1156755,
      overall_rank: 2301612,
      bank: 3,
      value: 1034,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 22,
      points: 67,
      total_points: 1178,
      rank: 2790499,
      rank_sort: 2796987,
      overall_rank: 2269420,
      bank: 5,
      value: 1039,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 26,
    },
    {
      event: 23,
      points: 69,
      total_points: 1247,
      rank: 2074672,
      rank_sort: 2082207,
      overall_rank: 2192120,
      bank: 5,
      value: 1045,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 21,
    },
    {
      event: 24,
      points: 97,
      total_points: 1336,
      rank: 588103,
      rank_sort: 588506,
      overall_rank: 1912753,
      bank: 20,
      value: 1041,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 2,
    },
    {
      event: 25,
      points: 55,
      total_points: 1387,
      rank: 4078807,
      rank_sort: 4081141,
      overall_rank: 2089609,
      bank: 36,
      value: 1047,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 3,
    },
    {
      event: 26,
      points: 88,
      total_points: 1475,
      rank: 1845660,
      rank_sort: 1849089,
      overall_rank: 1952755,
      bank: 9,
      value: 1047,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 27,
      points: 98,
      total_points: 1569,
      rank: 252557,
      rank_sort: 253578,
      overall_rank: 1604211,
      bank: 9,
      value: 1045,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 8,
    },
    {
      event: 28,
      points: 35,
      total_points: 1604,
      rank: 6104225,
      rank_sort: 6112818,
      overall_rank: 1747686,
      bank: 9,
      value: 1047,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 29,
      points: 46,
      total_points: 1646,
      rank: 1175178,
      rank_sort: 1177636,
      overall_rank: 1652450,
      bank: 30,
      value: 1044,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 30,
      points: 64,
      total_points: 1710,
      rank: 756137,
      rank_sort: 762193,
      overall_rank: 1504174,
      bank: 24,
      value: 1046,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 31,
      points: 59,
      total_points: 1761,
      rank: 869303,
      rank_sort: 870385,
      overall_rank: 1441852,
      bank: 62,
      value: 1047,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 8,
    },
    {
      event: 32,
      points: 76,
      total_points: 1829,
      rank: 287984,
      rank_sort: 289061,
      overall_rank: 1297904,
      bank: 46,
      value: 1047,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 10,
    },
    {
      event: 33,
      points: 60,
      total_points: 1881,
      rank: 388413,
      rank_sort: 390115,
      overall_rank: 1199977,
      bank: 0,
      value: 1048,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 34,
      points: 40,
      total_points: 1917,
      rank: 3888236,
      rank_sort: 3891765,
      overall_rank: 1232958,
      bank: 0,
      value: 1051,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 6,
    },
    {
      event: 35,
      points: 99,
      total_points: 2008,
      rank: 2019910,
      rank_sort: 2021394,
      overall_rank: 1267758,
      bank: 7,
      value: 1044,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 6,
    },
    {
      event: 36,
      points: 42,
      total_points: 2034,
      rank: 3398597,
      rank_sort: 3398804,
      overall_rank: 1407652,
      bank: 0,
      value: 1043,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 0,
    },
    {
      event: 37,
      points: 36,
      total_points: 2062,
      rank: 6164869,
      rank_sort: 6165250,
      overall_rank: 1584130,
      bank: 3,
      value: 1039,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 3,
    },
    {
      event: 38,
      points: 74,
      total_points: 2136,
      rank: 237989,
      rank_sort: 239122,
      overall_rank: 1395274,
      bank: 16,
      value: 1039,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 14,
    },
  ],
  past: [
    { season_name: '2016/17', total_points: 2018, rank: 523140 },
    { season_name: '2017/18', total_points: 2167, rank: 209949 },
    { season_name: '2018/19', total_points: 1954, rank: 2450738 },
    { season_name: '2019/20', total_points: 2318, rank: 31996 },
  ],
  chips: [
    { name: 'freehit', time: '2020-11-19T17:21:25.506000Z', event: 9 },
    { name: 'wildcard', time: '2020-12-08T18:58:31.144770Z', event: 12 },
    { name: '3xc', time: '2021-01-15T19:21:54.954819Z', event: 19 },
    { name: 'wildcard', time: '2021-01-22T14:07:07.386493Z', event: 20 },
    { name: 'bboost', time: '2021-01-29T12:00:53.043575Z', event: 21 },
  ],
};
