/**
 * Imports
 */
import { ArrowRightOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Box, Text } from 'components';
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const Anchor = styled.a`
  &:hover {
    color: blue;
  }
`;

const StyledFrame = styled.iframe`
  margin-top: 16px;
  border: none;
  width: 100%;
  min-height: calc(100vh - 200px);
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  @media (max-width: 600px) {
    min-height: 800px;
  }
`;

/**
 * Types
 */
export interface RulesPageProps {}

const LINK =
  'https://drive.google.com/file/d/1vSYuKOLxy3FFqoQV4ygQuKduvVtuzRoQ8Egp7VPZ52I/preview';
const RULES_LINK =
  'https://docs.google.com/document/d/e/2PACX-1vRPaulC3BmQdDuGT4pOQW5OIinS_ydlp_bdMabi7YsE468WB0vUi0NDstHHy6XTxawxFfDKLb1THrlJ/pub?embedded=true';

/**
 * RulesPage component
 */
const RulesPage: React.FC<RulesPageProps> = (props) => {
  return (
    <BaseLayout pageName="Reglementer">
      <Anchor
        href="https://docs.google.com/document/d/18bltREwWxaXT-p_rumCduJCSo3-yiMjHt4m3zrOLxq8/edit?usp=sharing"
        target="_blnk"
      >
        Link til reglementer <ArrowRightOutlined size={12} />
      </Anchor>
      <StyledFrame src={LINK} />
      {/* <StyledFrame src={RULES_LINK} /> */}
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { RulesPage };
