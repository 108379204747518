export const SMIDT = {
  name: 'Smidt',
  current: [
    {
      event: 1,
      points: 69,
      total_points: 69,
      rank: 914811,
      rank_sort: 945061,
      overall_rank: 914811,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 2,
      points: 55,
      total_points: 124,
      rank: 3585184,
      rank_sort: 3607794,
      overall_rank: 1435716,
      bank: 0,
      value: 1003,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 7,
    },
    {
      event: 3,
      points: 37,
      total_points: 153,
      rank: 4905929,
      rank_sort: 4911850,
      overall_rank: 2644003,
      bank: 0,
      value: 1009,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 26,
    },
    {
      event: 4,
      points: 47,
      total_points: 200,
      rank: 3634141,
      rank_sort: 3650292,
      overall_rank: 2687850,
      bank: 6,
      value: 1011,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 8,
    },
    {
      event: 5,
      points: 43,
      total_points: 235,
      rank: 6061635,
      rank_sort: 6063032,
      overall_rank: 3983693,
      bank: 11,
      value: 1013,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 9,
    },
    {
      event: 6,
      points: 44,
      total_points: 275,
      rank: 4562101,
      rank_sort: 4575641,
      overall_rank: 4265255,
      bank: 7,
      value: 1019,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 13,
    },
    {
      event: 7,
      points: 71,
      total_points: 342,
      rank: 631212,
      rank_sort: 635985,
      overall_rank: 3633845,
      bank: 11,
      value: 1022,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 7,
    },
    {
      event: 8,
      points: 68,
      total_points: 406,
      rank: 1338370,
      rank_sort: 1354136,
      overall_rank: 3233603,
      bank: 16,
      value: 1027,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 9,
      points: 55,
      total_points: 445,
      rank: 4082023,
      rank_sort: 4082609,
      overall_rank: 3851672,
      bank: 8,
      value: 1023,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 2,
    },
    {
      event: 10,
      points: 60,
      total_points: 505,
      rank: 765192,
      rank_sort: 780236,
      overall_rank: 3276682,
      bank: 15,
      value: 1026,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 11,
      points: 45,
      total_points: 550,
      rank: 6642721,
      rank_sort: 6646573,
      overall_rank: 3848223,
      bank: 14,
      value: 1032,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 12,
      points: 57,
      total_points: 607,
      rank: 2732472,
      rank_sort: 2756102,
      overall_rank: 3687195,
      bank: 4,
      value: 1034,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 13,
      points: 42,
      total_points: 649,
      rank: 3986119,
      rank_sort: 4024777,
      overall_rank: 3710800,
      bank: 4,
      value: 1036,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 14,
      points: 54,
      total_points: 695,
      rank: 4997836,
      rank_sort: 4998219,
      overall_rank: 4051511,
      bank: 1,
      value: 1036,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 11,
    },
    {
      event: 15,
      points: 54,
      total_points: 745,
      rank: 1317893,
      rank_sort: 1335232,
      overall_rank: 3834203,
      bank: 0,
      value: 1037,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 16,
      points: 49,
      total_points: 790,
      rank: 1402200,
      rank_sort: 1413569,
      overall_rank: 3661441,
      bank: 2,
      value: 1037,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 1,
    },
    {
      event: 17,
      points: 61,
      total_points: 851,
      rank: 3112618,
      rank_sort: 3130541,
      overall_rank: 3589227,
      bank: 5,
      value: 1041,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 18,
      points: 62,
      total_points: 913,
      rank: 53310,
      rank_sort: 62590,
      overall_rank: 2863541,
      bank: 5,
      value: 1043,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 12,
    },
    {
      event: 19,
      points: 137,
      total_points: 1038,
      rank: 39101,
      rank_sort: 39412,
      overall_rank: 1888365,
      bank: 1,
      value: 1038,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 0,
    },
    {
      event: 20,
      points: 54,
      total_points: 1084,
      rank: 1517597,
      rank_sort: 1521344,
      overall_rank: 1828667,
      bank: 26,
      value: 1040,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 3,
    },
    {
      event: 21,
      points: 48,
      total_points: 1124,
      rank: 4131308,
      rank_sort: 4134840,
      overall_rank: 2062831,
      bank: 86,
      value: 1043,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 14,
    },
    {
      event: 22,
      points: 45,
      total_points: 1169,
      rank: 5560660,
      rank_sort: 5577364,
      overall_rank: 2423983,
      bank: 60,
      value: 1038,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 30,
    },
    {
      event: 23,
      points: 59,
      total_points: 1224,
      rank: 4294744,
      rank_sort: 4306398,
      overall_rank: 2574689,
      bank: 20,
      value: 1048,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 26,
    },
    {
      event: 24,
      points: 86,
      total_points: 1294,
      rank: 1413804,
      rank_sort: 1414291,
      overall_rank: 2527909,
      bank: 2,
      value: 1042,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 15,
    },
    {
      event: 25,
      points: 83,
      total_points: 1369,
      rank: 522279,
      rank_sort: 525110,
      overall_rank: 2336639,
      bank: 9,
      value: 1045,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 10,
    },
    {
      event: 26,
      points: 86,
      total_points: 1439,
      rank: 2218504,
      rank_sort: 2219337,
      overall_rank: 2430702,
      bank: 1,
      value: 1042,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 4,
    },
    {
      event: 27,
      points: 83,
      total_points: 1514,
      rank: 1121323,
      rank_sort: 1123179,
      overall_rank: 2300927,
      bank: 6,
      value: 1042,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 12,
    },
    {
      event: 28,
      points: 33,
      total_points: 1539,
      rank: 6685508,
      rank_sort: 6687197,
      overall_rank: 2564456,
      bank: 1,
      value: 1043,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 3,
    },
    {
      event: 29,
      points: 50,
      total_points: 1585,
      rank: 875248,
      rank_sort: 885381,
      overall_rank: 2362187,
      bank: 23,
      value: 1045,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 30,
      points: 41,
      total_points: 1626,
      rank: 5256015,
      rank_sort: 5295037,
      overall_rank: 2444619,
      bank: 23,
      value: 1046,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 31,
      points: 63,
      total_points: 1689,
      rank: 524131,
      rank_sort: 530252,
      overall_rank: 2218224,
      bank: 43,
      value: 1042,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 32,
      points: 64,
      total_points: 1745,
      rank: 1302900,
      rank_sort: 1307756,
      overall_rank: 2143559,
      bank: 24,
      value: 1040,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: -1,
    },
    {
      event: 33,
      points: 75,
      total_points: 1812,
      rank: 20864,
      rank_sort: 21084,
      overall_rank: 1849502,
      bank: 51,
      value: 1043,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 3,
    },
    {
      event: 34,
      points: 47,
      total_points: 1855,
      rank: 2283152,
      rank_sort: 2290090,
      overall_rank: 1814735,
      bank: 15,
      value: 1047,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 35,
      points: 123,
      total_points: 1962,
      rank: 424659,
      rank_sort: 425095,
      overall_rank: 1664443,
      bank: 17,
      value: 1044,
      event_transfers: 5,
      event_transfers_cost: 16,
      points_on_bench: 12,
    },
    {
      event: 36,
      points: 48,
      total_points: 2006,
      rank: 2138998,
      rank_sort: 2152989,
      overall_rank: 1649163,
      bank: 33,
      value: 1046,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 37,
      points: 52,
      total_points: 2054,
      rank: 2174282,
      rank_sort: 2184521,
      overall_rank: 1654109,
      bank: 3,
      value: 1048,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 12,
    },
    {
      event: 38,
      points: 35,
      total_points: 2085,
      rank: 7163522,
      rank_sort: 7165707,
      overall_rank: 1832457,
      bank: 30,
      value: 1046,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 9,
    },
  ],
  past: [
    { season_name: '2016/17', total_points: 1844, rank: 1535183 },
    { season_name: '2017/18', total_points: 2097, rank: 508452 },
    { season_name: '2018/19', total_points: 2211, rank: 235117 },
    { season_name: '2019/20', total_points: 2153, rank: 534988 },
  ],
  chips: [
    { name: 'wildcard', time: '2020-09-14T21:02:08.297701Z', event: 2 },
    { name: '3xc', time: '2020-10-30T16:19:06.809950Z', event: 7 },
    { name: 'freehit', time: '2021-01-10T18:55:51.742866Z', event: 18 },
    { name: 'bboost', time: '2021-01-15T23:16:16.062003Z', event: 19 },
    { name: 'wildcard', time: '2021-02-02T13:13:56.940903Z', event: 22 },
  ],
};
