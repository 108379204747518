import {
  BookOutlined,
  CloseOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  FolderOutlined,
  HomeOutlined,
  MenuOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Icon, Text } from 'components';
import { firebaseLogout } from 'firebaseAPI';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router';
import * as Styles from './MobileMenu.styles';

const MobileMenu: React.FC = () => {
  const [currentPath, setCurrentPath] = useState('');
  const [visible, setVisible] = useState(false);
  const navigate = useHistory();

  useEffect(() => {
    const pathName = location.pathname.split('/')[1];
    setCurrentPath(`/${[pathName]}`);
  }, [location.pathname]);

  const sidebarItems = useMemo(() => {
    return [
      {
        icon: <HomeOutlined />,
        path: ROUTES.home.path,
        text: 'Forside',
      },
      {
        icon: <BookOutlined />,
        path: ROUTES.rules.path,
        text: 'Reglementer',
      },
      {
        icon: <FileTextOutlined />,
        path: ROUTES.meetings.path,
        text: 'Møder',
      },
      {
        icon: <FolderOutlined />,
        path: ROUTES.materials.path,
        text: 'Materiale',
      },
      {
        icon: <ExperimentOutlined />,
        path: ROUTES.brewery.path,
        text: 'Bryg',
      },
      {
        icon: <TrophyOutlined />,
        path: ROUTES.fpl.path,
        text: 'FPL',
      },
      {
        icon: <UserOutlined />,
        path: ROUTES.account.path,
        text: 'Konto',
      },
    ];
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const navigateTo = (path: string) => {
    handleClose();
    navigate.push(path);
  };

  const handleLogout = async () => {
    await firebaseLogout();
    navigateTo(ROUTES.login.path);
  };

  return (
    <div>
      <Styles.Wrapper onClick={handleOpen}>
        <MenuOutlined />
      </Styles.Wrapper>

      <Styles.Drawer
        visible={visible}
        width="100vw"
        placement="right"
        closable
        closeIcon={<CloseOutlined />}
        onClose={handleClose}
      >
        <Styles.Container>
          <Styles.Header>
            <Styles.LogoWrapper>FRI13</Styles.LogoWrapper>
          </Styles.Header>
          <Styles.Content>
            <Styles.Top>
              {sidebarItems.map((item) => {
                const isSelected = currentPath === item.path;

                return (
                  <Styles.Link
                    key={item.path}
                    selected={isSelected}
                    onClick={() => navigateTo(item.path)}
                  >
                    <Text variant="ui2">{item.text}</Text>
                    <Icon
                      icon={item.icon}
                      size={20}
                      color={isSelected ? 'Orange500' : undefined}
                    />
                  </Styles.Link>
                );
              })}
            </Styles.Top>
            <Styles.Bottom>
              <Text variant="ui3" color="ui" onClick={handleLogout}>
                Log ud
              </Text>
            </Styles.Bottom>
          </Styles.Content>
          {/* <Styles.Footer>
            <Styles.Row>
              <Styles.CardIcon>
                <HomeOutlined />
              </Styles.CardIcon>
              <Styles.CardIcon>
                <HomeOutlined />
              </Styles.CardIcon>
            </Styles.Row>
          </Styles.Footer> */}
        </Styles.Container>
      </Styles.Drawer>
    </div>
  );
};

export { MobileMenu };
