/**
 * Imports
 */
import { Box, Text } from 'components';
import { useGoogleDriveApi } from 'features/shared';
import * as React from 'react';
import { styled } from 'theme';
import { gapi } from 'gapi-script';
import { orderBy, sortBy } from 'lodash';
import { Card } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router';
/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
`;
const MeetingImg = styled.img`
  height: 150px;
`;

/**
 * CONST
 */
const FOLDER_IDS = {
  MEETINGS:
    '0B9iHxSUXvFPufkROUFNnUmQwZk9zZExQbWsyMVlNUWxmZkFvWVlQNUJycTZ0Q1FFNUJCVnM',
};

/**
 * Types
 */
export interface MeetingsListProps {}
export type GoogleDriveDocument = {
  id: string;
  name: string;
  mimeType: string;
  modifiedTime: string;
  webViewLink: string;
};

/**
 * MeetingsList component
 */
const MeetingsList: React.FC<MeetingsListProps> = (props) => {
  const navigation = useHistory();
  const driveApi = gapi?.client?.drive;
  const [
    isFetchingGoogleDriveFiles,
    setIsFetchingGoogleDriveFiles,
  ] = React.useState(false);
  const [meetings, setMeetings] = React.useState<GoogleDriveDocument[]>([]);

  const _getFiles = async () => {
    setIsFetchingGoogleDriveFiles(true);

    if (!driveApi) {
      return;
    }

    const resp = await driveApi.files.list({
      // Meta file props: https://developers.google.com/drive/api/v3/reference/files
      fields:
        'files(id, name, mimeType, modifiedTime, webViewLink, webContentLink)',
      // https://developers.google.com/drive/api/v3/reference/query-ref
      q: `'${FOLDER_IDS.MEETINGS}' in parents and trashed=false`,
    });
    const res = JSON.parse(resp.body);
    const files = res.files as GoogleDriveDocument[];
    const onlyFiles = files.filter(
      (file) => file.mimeType !== 'application/vnd.google-apps.folder',
    );
    const orderedFiles = sortBy(onlyFiles, (file) =>
      parseInt(file.name, 10),
    ).reverse();
    setIsFetchingGoogleDriveFiles(false);
    setMeetings(orderedFiles);
  };

  const _goTo = (id: string) => {
    const path = `${ROUTES.meeting.path}/${id}`;
    navigation.push(path);
  };

  React.useEffect(() => {
    _getFiles();
  }, []);

  if (isFetchingGoogleDriveFiles) {
    return (
      <Box>
        <Text variant="ui2">...henter dokumenter</Text>
      </Box>
    );
  }

  return (
    <Root>
      {meetings?.map((meeting) => {
        return (
          <Box key={meeting.id} width={'320px'} m={'16px'}>
            <Card
              title={
                <Text variant="ui1" fontFamily={'heading'}>
                  {meeting.name}
                </Text>
              }
              extra={
                <a target="_blank" rel="noreferrer" href={meeting.webViewLink}>
                  <GoogleOutlined />
                </a>
              }
            >
              <Box onClick={() => _goTo(meeting.id)}>
                <Text variant="ui2" mb={'8px'}>
                  Vært Værtensen
                </Text>
                <Text variant="ui3" mb={'4px'}>
                  Gave: Lorem
                </Text>
                <Text variant="ui3" mb={'4px'}>
                  Mad: Ipsum
                </Text>
                <Text variant="ui3" mb={'16px'}>
                  Underholdning: Lorem ipsum
                </Text>
                <MeetingImg
                  src="https://drive.google.com/uc?export=view&id=1DJcMh5AuUkICuKnbMuT87OP3OZskeI7x"
                  alt="image"
                />
              </Box>
            </Card>
          </Box>
        );
      })}
    </Root>
  );
};

/**
 * Exports
 */
export { MeetingsList };
