/**
 * Imports
 */

import { BreweryListPage } from 'features/brewery';
import { FPLOverviewPage } from 'features/fpl';
import { MaterialsPage } from 'features/materials';
import { MeetingPage, MeetingsListPage } from 'features/meetings';
import { HomePage } from 'features/navigation/pages';
import { RulesPage } from 'features/rules';
import { AccountPage, LoginPage } from 'features/user/pages';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from 'router';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: ${(props) => props.theme.colors.Background.Primary}; */

  height: 100%;
  min-height: 100%;
`;

/**
 * Types
 */
export type RoutesType =
  | 'account'
  | 'brewery'
  | 'fpl'
  | 'home'
  | 'login'
  | 'materials'
  | 'meeting'
  | 'meetings'
  | 'rules';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  pageName: string | React.ReactNode;
  component: React.FC;
  path: string;
  route: string;
  pageId: RoutesType;
  isPrivate: boolean;
};

export interface RootRouterProps {}

/**
 * Routes settings
 */
export const ROUTES: RouteLookup = {
  home: {
    component: HomePage,
    isPrivate: true,
    pageId: 'home',
    pageName: <Trans i18nKey="Global.pages.home" />,
    path: '/',
    route: '/',
  },
  rules: {
    component: RulesPage,
    isPrivate: true,
    pageId: 'rules',
    pageName: 'Rules',
    path: '/rules',
    route: '/rules',
  },
  account: {
    component: AccountPage,
    isPrivate: true,
    pageId: 'account',
    pageName: <Trans i18nKey="Global.pages.account" />,
    path: '/account',
    route: '/account',
  },
  fpl: {
    component: FPLOverviewPage,
    isPrivate: true,
    pageId: 'fpl',
    pageName: 'FPL',
    path: '/fpl',
    route: '/fpl',
  },
  meetings: {
    component: MeetingsListPage,
    isPrivate: true,
    pageId: 'meetings',
    pageName: 'meetings',
    path: '/meetings',
    route: '/meetings',
  },
  meeting: {
    component: MeetingPage,
    isPrivate: true,
    pageId: 'meeting',
    pageName: 'meeting',
    path: '/meetings',
    route: '/meetings/:meetingId',
  },
  materials: {
    component: MaterialsPage,
    isPrivate: true,
    pageId: 'materials',
    pageName: 'materials',
    path: '/materials',
    route: '/materials',
  },
  brewery: {
    component: BreweryListPage,
    isPrivate: true,
    pageId: 'brewery',
    pageName: 'brewery',
    path: '/brewery',
    route: '/brewery',
  },
  login: {
    component: LoginPage,
    isPrivate: false,
    pageId: 'login',
    pageName: 'Login',
    path: '/login',
    route: '/login',
  },
};

/**
 * RootRouter component
 */
/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  return (
    <BrowserRouter>
      <Root>
        <Switch>
          {Object.values(ROUTES).map((route) => {
            const RouteContainer = route.isPrivate ? PrivateRoute : PublicRoute;

            return (
              <RouteContainer
                exact
                key={route.route}
                path={route.route}
                component={route.component}
              />
            );
          })}
          <Route path="*" component={() => <h1>404</h1>} />
        </Switch>
      </Root>
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };
