/**
 * Notes
 * Code example
 * https://github.com/willikay11/React-Google-Drive-Tutorial/blob/master/src/Components/SelectSource/index.js
 * // Gapi
 * https://github.com/partnerhero/gapi-script
 * https://medium.com/@bretcameron/how-to-use-the-google-drive-api-with-javascript-57a6cc9e5262
 * https://developers.google.com/drive/api/v3/reference/files/list?apix=true&apix_params=%7B%22q%22%3A%22Fri13%22%7D#try-it
 */
/**
 * Imports
 */
import { GoogleDriveDocumentType } from 'features/meetings';
import { gapi } from 'gapi-script';
import * as React from 'react';

/**
 * CONST
 *  * https://console.developers.google.com/apis/credentials?project=fri13-cdf94
 */
// Client ID and API key from the Developer Console
const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;

// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';

/**
 * Types
 */
export type GoogleDriveAPIHandlers = {
  onGetDocuments: () => Promise<void>;
};
export type GoogleDriveAPIType = {
  handlers: GoogleDriveAPIHandlers;
};

/**
 * useGoogleDriveApi
 */
const useGoogleDriveApi = (): GoogleDriveAPIType => {
  const [signedInUser, setSignedInUser] = React.useState();
  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = React.useState(
    false,
  );

  /**
   *  Sign in the user upon button click.
   */
  const handleAuthClick = () => {
    gapi.auth2.getAuthInstance().signIn();
  };
  /**
   *  Called when the signed in status changes, to update the UI
   *  appropriately. After a sign-in, the API is called.
   */
  const updateSigninStatus = (isSignedIn: any) => {
    if (isSignedIn) {
      // Set the signed in user
      setSignedInUser(gapi.auth2.getAuthInstance().currentUser.le.wt);
      setIsLoadingGoogleDriveApi(false);
    } else {
      // prompt user to sign in
      handleAuthClick();
    }
  };
  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  const initClient = () => {
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        function () {
          // Listen for sign-in state changes.
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

          // Handle the initial sign-in state.
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        function (err: any) {
          console.log('Err', err);
        },
      );
  };
  /**
   * handleClientLoad
   */
  const handleClientLoad = () => {
    if (!gapi.client) {
      gapi.load('client:auth2', initClient);
    } else {
      console.log('already there');
    }
  };
  /**
   * Inti
   */
  React.useEffect(() => {
    handleClientLoad();
  }, []);

  const handlers: GoogleDriveAPIHandlers = {
    /**
     * onGetDocuments
     */
    onGetDocuments: async () => {
      console.log('onGetDocuments');
    },
  };
  return { handlers };
};

/**
 * Exports
 */
export { useGoogleDriveApi };
