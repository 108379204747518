export const KRAG_22 = {
  name: 'Krag',
  current: [
    {
      event: 1,
      points: 79,
      total_points: 79,
      rank: 2329403,
      rank_sort: 2335429,
      overall_rank: 2329400,
      bank: 5,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 2,
      points: 54,
      total_points: 133,
      rank: 3959589,
      rank_sort: 3962737,
      overall_rank: 2394295,
      bank: 5,
      value: 1002,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 10,
    },
    {
      event: 3,
      points: 64,
      total_points: 193,
      rank: 1868295,
      rank_sort: 1869584,
      overall_rank: 2197247,
      bank: 27,
      value: 1005,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 4,
      points: 64,
      total_points: 249,
      rank: 2534171,
      rank_sort: 2535112,
      overall_rank: 2299444,
      bank: 43,
      value: 1005,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 14,
    },
    {
      event: 5,
      points: 58,
      total_points: 307,
      rank: 3394817,
      rank_sort: 3399064,
      overall_rank: 2262119,
      bank: 38,
      value: 1005,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 6,
      points: 56,
      total_points: 363,
      rank: 864565,
      rank_sort: 866803,
      overall_rank: 1755373,
      bank: 38,
      value: 1011,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 4,
    },
    {
      event: 7,
      points: 50,
      total_points: 409,
      rank: 1223935,
      rank_sort: 1224697,
      overall_rank: 1590277,
      bank: 6,
      value: 1011,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 8,
      points: 74,
      total_points: 483,
      rank: 149219,
      rank_sort: 149501,
      overall_rank: 952095,
      bank: 3,
      value: 1015,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 8,
    },
    {
      event: 9,
      points: 94,
      total_points: 577,
      rank: 765851,
      rank_sort: 766831,
      overall_rank: 650515,
      bank: 0,
      value: 1014,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 10,
      points: 39,
      total_points: 616,
      rank: 5196802,
      rank_sort: 5199919,
      overall_rank: 767800,
      bank: 1,
      value: 1020,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 11,
      points: 53,
      total_points: 669,
      rank: 1641110,
      rank_sort: 1643966,
      overall_rank: 705891,
      bank: 1,
      value: 1021,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 14,
    },
    {
      event: 12,
      points: 63,
      total_points: 732,
      rank: 3120556,
      rank_sort: 3123337,
      overall_rank: 740656,
      bank: 8,
      value: 1028,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 6,
    },
    {
      event: 13,
      points: 57,
      total_points: 781,
      rank: 1496349,
      rank_sort: 1497222,
      overall_rank: 753764,
      bank: 7,
      value: 1030,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 2,
    },
    {
      event: 14,
      points: 66,
      total_points: 847,
      rank: 2757717,
      rank_sort: 2762840,
      overall_rank: 753345,
      bank: 7,
      value: 1034,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 15,
      points: 45,
      total_points: 892,
      rank: 3794247,
      rank_sort: 3797066,
      overall_rank: 790838,
      bank: 0,
      value: 1036,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 9,
    },
    {
      event: 16,
      points: 66,
      total_points: 958,
      rank: 2294668,
      rank_sort: 2296504,
      overall_rank: 761430,
      bank: 0,
      value: 1041,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 17,
      points: 45,
      total_points: 999,
      rank: 4687806,
      rank_sort: 4688287,
      overall_rank: 944904,
      bank: 2,
      value: 1040,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 18,
      points: 66,
      total_points: 1065,
      rank: 417168,
      rank_sort: 418705,
      overall_rank: 718905,
      bank: 2,
      value: 1038,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 5,
    },
    {
      event: 19,
      points: 45,
      total_points: 1110,
      rank: 2491388,
      rank_sort: 2493309,
      overall_rank: 694966,
      bank: 28,
      value: 1039,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 20,
      points: 32,
      total_points: 1138,
      rank: 5790726,
      rank_sort: 5792992,
      overall_rank: 801797,
      bank: 29,
      value: 1039,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 21,
      points: 61,
      total_points: 1195,
      rank: 1674126,
      rank_sort: 1675117,
      overall_rank: 819559,
      bank: 2,
      value: 1039,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 1,
    },
    {
      event: 22,
      points: 49,
      total_points: 1236,
      rank: 4918693,
      rank_sort: 4919638,
      overall_rank: 984136,
      bank: 55,
      value: 1035,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 2,
    },
    {
      event: 23,
      points: 36,
      total_points: 1268,
      rank: 4102970,
      rank_sort: 4106575,
      overall_rank: 1022951,
      bank: 36,
      value: 1036,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 8,
    },
    {
      event: 24,
      points: 70,
      total_points: 1334,
      rank: 1603426,
      rank_sort: 1604981,
      overall_rank: 995495,
      bank: 1,
      value: 1039,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 12,
    },
    {
      event: 25,
      points: 58,
      total_points: 1388,
      rank: 2268938,
      rank_sort: 2270901,
      overall_rank: 991248,
      bank: 6,
      value: 1043,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 2,
    },
    {
      event: 26,
      points: 85,
      total_points: 1465,
      rank: 4433785,
      rank_sort: 4434021,
      overall_rank: 1287601,
      bank: 4,
      value: 1043,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 4,
    },
    {
      event: 27,
      points: 68,
      total_points: 1533,
      rank: 157276,
      rank_sort: 159111,
      overall_rank: 1058661,
      bank: 4,
      value: 1043,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 28,
      points: 93,
      total_points: 1622,
      rank: 1463025,
      rank_sort: 1464192,
      overall_rank: 985424,
      bank: 8,
      value: 1042,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 31,
    },
    {
      event: 29,
      points: 91,
      total_points: 1705,
      rank: 1442300,
      rank_sort: 1443608,
      overall_rank: 979535,
      bank: 0,
      value: 1042,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 6,
    },
    {
      event: 30,
      points: 50,
      total_points: 1755,
      rank: 1632216,
      rank_sort: 1634571,
      overall_rank: 940533,
      bank: 0,
      value: 1043,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 31,
      points: 53,
      total_points: 1804,
      rank: 2089301,
      rank_sort: 2090063,
      overall_rank: 930423,
      bank: 0,
      value: 1039,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 32,
      points: 61,
      total_points: 1865,
      rank: 2118885,
      rank_sort: 2121351,
      overall_rank: 907814,
      bank: 0,
      value: 1040,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 20,
    },
    {
      event: 33,
      points: 113,
      total_points: 1974,
      rank: 10741,
      rank_sort: 10783,
      overall_rank: 653203,
      bank: 2,
      value: 1041,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 34,
      points: 85,
      total_points: 2055,
      rank: 198253,
      rank_sort: 198582,
      overall_rank: 528036,
      bank: 6,
      value: 1042,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 35,
      points: 51,
      total_points: 2098,
      rank: 2739246,
      rank_sort: 2739827,
      overall_rank: 542478,
      bank: 8,
      value: 1039,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 8,
    },
    {
      event: 36,
      points: 112,
      total_points: 2206,
      rank: 1026098,
      rank_sort: 1027042,
      overall_rank: 510571,
      bank: 1,
      value: 1036,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 37,
      points: 62,
      total_points: 2268,
      rank: 1593935,
      rank_sort: 1595601,
      overall_rank: 512323,
      bank: 10,
      value: 1027,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 20,
    },
    {
      event: 38,
      points: 69,
      total_points: 2329,
      rank: 1581030,
      rank_sort: 1582100,
      overall_rank: 540673,
      bank: 5,
      value: 1031,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
  ],
  past: [
    {
      season_name: '2016/17',
      total_points: 2018,
      rank: 523140,
    },
    {
      season_name: '2017/18',
      total_points: 2167,
      rank: 209949,
    },
    {
      season_name: '2018/19',
      total_points: 1954,
      rank: 2450738,
    },
    {
      season_name: '2019/20',
      total_points: 2318,
      rank: 31996,
    },
    {
      season_name: '2020/21',
      total_points: 2136,
      rank: 1395274,
    },
  ],
  chips: [
    {
      name: '3xc',
      time: '2021-10-15T10:23:31.061508Z',
      event: 8,
    },
    {
      name: 'wildcard',
      time: '2021-10-17T20:08:22.089498Z',
      event: 9,
    },
    {
      name: 'freehit',
      time: '2021-12-18T11:41:54.639374Z',
      event: 18,
    },
    {
      name: 'freehit',
      time: '2022-02-21T10:09:41.131437Z',
      event: 27,
    },
    {
      name: 'bboost',
      time: '2022-04-08T18:18:54.004499Z',
      event: 33,
    },
    {
      name: 'wildcard',
      time: '2022-05-08T08:03:50.998920Z',
      event: 37,
    },
  ],
};
