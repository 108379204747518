/**
 * Imports
 */
import { Button, VibrantIcon } from 'components';
import { firebaseSignupWithGoogle } from 'firebaseAPI';
import * as React from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from 'router';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledButton = styled(Button)`
  background-color: #4285f4;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: #2472f2;
  }
`;

/**
 * GoogleSignupButton component
 */
const GoogleSignupButton: React.FC = () => {
  const navigation = useHistory();
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);

  const _handleGoogleLogin = async () => {
    setIsLoading(true);
    console.log('_handleGoogleLogin');
    try {
      await firebaseSignupWithGoogle();
      setIsLoading(false);
      navigation.push(ROUTES.home.path);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  return (
    <StyledButton
      type="primary"
      onClick={_handleGoogleLogin}
      block
      loading={isLoading}
    >
      <VibrantIcon icon="google" color="White000" />
      Signup with Google
    </StyledButton>
  );
};

/**
 * Exports
 */
export { GoogleSignupButton };
