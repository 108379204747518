export const BALDER = {
  name: 'Balder',
  current: [
    {
      event: 1,
      points: 33,
      total_points: 33,
      rank: 4614059,
      rank_sort: 4698539,
      overall_rank: 4614059,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 2,
      points: 89,
      total_points: 122,
      rank: 378558,
      rank_sort: 387702,
      overall_rank: 1620860,
      bank: 4,
      value: 998,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 3,
      points: 38,
      total_points: 160,
      rank: 4580037,
      rank_sort: 4621017,
      overall_rank: 2056920,
      bank: 4,
      value: 1001,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 4,
      points: 57,
      total_points: 209,
      rank: 2229563,
      rank_sort: 2235533,
      overall_rank: 2143576,
      bank: 9,
      value: 1000,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 2,
    },
    {
      event: 5,
      points: 83,
      total_points: 284,
      rank: 609438,
      rank_sort: 615065,
      overall_rank: 1488802,
      bank: 3,
      value: 1000,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 1,
    },
    {
      event: 6,
      points: 49,
      total_points: 325,
      rank: 3478789,
      rank_sort: 3488678,
      overall_rank: 1912339,
      bank: 7,
      value: 1001,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 7,
    },
    {
      event: 7,
      points: 51,
      total_points: 376,
      rank: 4076377,
      rank_sort: 4147780,
      overall_rank: 2050842,
      bank: 7,
      value: 1003,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 13,
    },
    {
      event: 8,
      points: 55,
      total_points: 431,
      rank: 3710572,
      rank_sort: 3766752,
      overall_rank: 2184478,
      bank: 7,
      value: 1007,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 9,
      points: 69,
      total_points: 500,
      rank: 1457392,
      rank_sort: 1487974,
      overall_rank: 1766880,
      bank: 2,
      value: 1003,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 10,
      points: 45,
      total_points: 545,
      rank: 3546511,
      rank_sort: 3613787,
      overall_rank: 1829004,
      bank: 2,
      value: 1004,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 11,
      points: 45,
      total_points: 582,
      rank: 6663713,
      rank_sort: 6665828,
      overall_rank: 2814752,
      bank: 5,
      value: 1003,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 12,
      points: 52,
      total_points: 630,
      rank: 3914978,
      rank_sort: 3940976,
      overall_rank: 3003970,
      bank: 5,
      value: 1002,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 7,
    },
    {
      event: 13,
      points: 29,
      total_points: 651,
      rank: 7059433,
      rank_sort: 7060699,
      overall_rank: 3656560,
      bank: 9,
      value: 999,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 14,
      points: 83,
      total_points: 734,
      rank: 723771,
      rank_sort: 742746,
      overall_rank: 3054461,
      bank: 1,
      value: 997,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 15,
      points: 41,
      total_points: 775,
      rank: 3880167,
      rank_sort: 3944920,
      overall_rank: 3094881,
      bank: 1,
      value: 987,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 16,
      points: 27,
      total_points: 798,
      rank: 6428075,
      rank_sort: 6434815,
      overall_rank: 3474573,
      bank: 1,
      value: 987,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 0,
    },
    {
      event: 17,
      points: 71,
      total_points: 865,
      rank: 1291360,
      rank_sort: 1309386,
      overall_rank: 3275205,
      bank: 5,
      value: 988,
      event_transfers: 2,
      event_transfers_cost: 4,
      points_on_bench: 12,
    },
    {
      event: 18,
      points: 55,
      total_points: 912,
      rank: 425553,
      rank_sort: 426954,
      overall_rank: 2885242,
      bank: 3,
      value: 991,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 19,
      points: 99,
      total_points: 1003,
      rank: 1012288,
      rank_sort: 1017650,
      overall_rank: 2577434,
      bank: 4,
      value: 991,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 5,
    },
    {
      event: 20,
      points: 58,
      total_points: 1049,
      rank: 1038642,
      rank_sort: 1040368,
      overall_rank: 2492898,
      bank: 32,
      value: 992,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 3,
    },
    {
      event: 21,
      points: 37,
      total_points: 1074,
      rank: 6038230,
      rank_sort: 6039673,
      overall_rank: 2998989,
      bank: 0,
      value: 993,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 0,
    },
    {
      event: 22,
      points: 68,
      total_points: 1142,
      rank: 2580172,
      rank_sort: 2622964,
      overall_rank: 2886671,
      bank: 0,
      value: 997,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 7,
    },
    {
      event: 23,
      points: 62,
      total_points: 1204,
      rank: 3509405,
      rank_sort: 3569493,
      overall_rank: 2906815,
      bank: 0,
      value: 1000,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 8,
    },
    {
      event: 24,
      points: 84,
      total_points: 1272,
      rank: 1587571,
      rank_sort: 1588036,
      overall_rank: 2852563,
      bank: 1,
      value: 992,
      event_transfers: 6,
      event_transfers_cost: 16,
      points_on_bench: 0,
    },
    {
      event: 25,
      points: 57,
      total_points: 1317,
      rank: 3800591,
      rank_sort: 3801971,
      overall_rank: 3046063,
      bank: 11,
      value: 992,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 4,
    },
    {
      event: 26,
      points: 72,
      total_points: 1377,
      rank: 4784206,
      rank_sort: 4786948,
      overall_rank: 3247384,
      bank: 5,
      value: 988,
      event_transfers: 4,
      event_transfers_cost: 12,
      points_on_bench: 6,
    },
    {
      event: 27,
      points: 94,
      total_points: 1471,
      rank: 388448,
      rank_sort: 396359,
      overall_rank: 2856413,
      bank: 0,
      value: 985,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 3,
    },
    {
      event: 28,
      points: 29,
      total_points: 1500,
      rank: 7167797,
      rank_sort: 7223158,
      overall_rank: 3051040,
      bank: 0,
      value: 986,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 29,
      points: 64,
      total_points: 1564,
      rank: 274752,
      rank_sort: 277397,
      overall_rank: 2609335,
      bank: 32,
      value: 983,
      event_transfers: 2,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 30,
      points: 58,
      total_points: 1622,
      rank: 1528949,
      rank_sort: 1588214,
      overall_rank: 2490048,
      bank: 32,
      value: 983,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 31,
      points: 27,
      total_points: 1645,
      rank: 7198716,
      rank_sort: 7200160,
      overall_rank: 2709725,
      bank: 8,
      value: 981,
      event_transfers: 3,
      event_transfers_cost: 4,
      points_on_bench: 4,
    },
    {
      event: 32,
      points: 80,
      total_points: 1717,
      rank: 147449,
      rank_sort: 150121,
      overall_rank: 2442274,
      bank: 9,
      value: 978,
      event_transfers: 3,
      event_transfers_cost: 8,
      points_on_bench: 6,
    },
    {
      event: 33,
      points: 39,
      total_points: 1756,
      rank: 3306022,
      rank_sort: 3323416,
      overall_rank: 2421855,
      bank: 57,
      value: 976,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 0,
    },
    {
      event: 34,
      points: 36,
      total_points: 1792,
      rank: 4847296,
      rank_sort: 4877047,
      overall_rank: 2458183,
      bank: 5,
      value: 979,
      event_transfers: 1,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
    {
      event: 35,
      points: 47,
      total_points: 1839,
      rank: 7832398,
      rank_sort: 7849888,
      overall_rank: 2855524,
      bank: 5,
      value: 976,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 15,
    },
    {
      event: 36,
      points: 61,
      total_points: 1892,
      rank: 462548,
      rank_sort: 464709,
      overall_rank: 2726588,
      bank: 0,
      value: 972,
      event_transfers: 4,
      event_transfers_cost: 8,
      points_on_bench: 0,
    },
    {
      event: 37,
      points: 50,
      total_points: 1942,
      rank: 2443929,
      rank_sort: 2541942,
      overall_rank: 2702070,
      bank: 0,
      value: 971,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 2,
    },
    {
      event: 38,
      points: 75,
      total_points: 2017,
      rank: 183834,
      rank_sort: 192648,
      overall_rank: 2461378,
      bank: 14,
      value: 969,
      event_transfers: 0,
      event_transfers_cost: 0,
      points_on_bench: 1,
    },
  ],
  past: [
    {
      season_name: '2016/17',
      total_points: 1999,
      rank: 609223,
    },
    {
      season_name: '2017/18',
      total_points: 1883,
      rank: 2157368,
    },
    {
      season_name: '2018/19',
      total_points: 2002,
      rank: 1862916,
    },
    {
      season_name: '2019/20',
      total_points: 1879,
      rank: 3343379,
    },
  ],
  chips: [
    {
      name: 'freehit',
      time: '2020-11-26T14:04:40.586258Z',
      event: 10,
    },
    {
      name: 'wildcard',
      time: '2020-12-26T09:35:15.422279Z',
      event: 15,
    },
    {
      name: '3xc',
      time: '2020-12-28T07:58:04.645791Z',
      event: 16,
    },
    {
      name: 'bboost',
      time: '2021-02-13T04:51:38.764480Z',
      event: 24,
    },
    {
      name: 'wildcard',
      time: '2021-05-23T04:45:52.046328Z',
      event: 38,
    },
  ],
};
